$base--8: 8px;
$gutter--12: 12px;
$gutter--24: 24px;

$input-icon-position: 10px;

$border-spacing: $base--8 * 4;
$gutter-spacing: $gutter--24;

$default-transition: 0.2s all ease;
$bezier: cubic-bezier(0.23, 1, 0.32, 1);
$radius: 5px;

$transition: 175ms ease-out;
$card_box_shadow: 0 0 4px rgba(0, 0, 0, 0.2);
$global_box_shadow: 0 0 4px rgba(0, 0, 0, 0.2); // used on form inputs & card styles
$navigation_height: rem-calc(72);

@import "../../abstracts/colours";
@import "../../abstracts/variables";
@import "../../abstracts/breakpoints";

$tweakpoints: (
  "edit-program__mobile": 768px,
);

.modal--edit-program {
  $details-padding: $base--8 * 5;

  .donate_signin_content {
    padding: 24px;
  }

  .edit-program {
    max-width: 780px;
    display: flex;
    padding: 0;

    h2 {
      margin-bottom: $gutter--12 * 3;
    }

    &__recipient {
      flex: 0 0 auto;
      padding: $gutter--24 $gutter--12 * 3;
      padding-top: $gutter--12 * 5;
    }

    &__details {
      border-left: 1px solid $border--light;
      // padding:$details-padding;
      padding-bottom: 0;

      .form__wrapper {
        max-height: 80vh;

        @include respond-to("small-down", max) {
          max-height: none;
        }
      }
    }

    .modal__submit-buttons {
      // padding:0 $details-padding;
      // margin-left: -$details-padding;
      // margin-right: -$details-padding;
      // box-sizing: content-box;
    }

    .form__date {
      .react-datepicker-popper {
        left: auto !important;
        right: 0 !important;
        z-index: 99;
      }

      .react-datepicker__triangle {
        left: auto !important;
        right: 50px;
      }
    }
  }

  @include respond-to("edit-program__mobile", "max") {
    padding: $gutter--12 * 2;

    // .modal__close {display:none;}

    .edit-program {
      flex-direction: column;
      max-height: calc(100vh - 32px - 32px);

      &__recipient {
        padding: 24px;
      }

      &__details {
        position: static;
        overflow-y: scroll;
      }

      .edit-program__recipient {
        padding: 24px;
      }

      .program {
        &__content {
          padding: 0;
          margin: 0;
          position: relative;
          display: block;
          max-width: none;

          h2,
          p {
            padding-left: calc(72px + 24px);
            text-align: left;
            margin-bottom: 8px;
          }
        }

        &__profile-pic {
          width: 72px;
          height: 72px;
          margin-bottom: 0;
          position: absolute;
          left: 0;
          top: 0;
        }

        &__recipient {
          height: auto;
          padding-top: $gutter--12;
        }
      }

      .form__wrapper {
        height: auto;
        padding: 24px;
        overflow: visible;
      }
    }
  }
}

.direct-deposit-info {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  strong {
    display: block;
    margin-bottom: $base--8/2;
  }

  > * {
    margin-bottom: $base--8 * 3;
    padding-right: $base--8 * 3;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    flex: 1 1 auto;
  }

  &__bsb {
    flex: 1 1 auto;
  }

  &__number {
    flex: 1 1 auto;
  }

  &__reference {
    flex: 1 1 auto;
  }

  &__international {
    flex: 1 1 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 24px;

    span {
      grid-column-start: 2;
    }
  }
}

//	-------------------------------------------------------------
//
//	COLORS
//
//	-------------------------------------------------------------

$alpha				: #4a90e2; // blue
$beta				: #bebec6; // grey
$gamma				: #5a5a62; // black
$delta				: #f2f2f2; // light grey - background of tabs, radio buttons
$delta_light        : #f9f9f9;

$body_font_color	: $gamma;

$kappa				: #e6e6eD; // grey - used for borders
$psi				: #e4eefb; // lighter version of alpha
$omega				: #8c8c94; // mid grey - used search button, form notes, btn_sec

$affirmative		: #50c736;
$warning  			: #FF3838; 
$alert  			: #c70004;

$select_color       : rgba(0,0,0,0.2);

::selection { background-color: $select_color; }
::moz-selection { background-color: $select_color; }
::webkit-selection { background-color: $select_color; }

@import "../abstracts/all";

.table {
  width: 100%;
  overflow: auto;

  &__wrap {
    min-width: 600px;
  }

  &__header {
    margin-top: $base--8;
    margin-bottom: 1px;
    border-bottom: 2px solid #4a90e2;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    min-width: 100%;

    ~ .table__header {
      margin-top: 0;
      font-size: smaller;
      border-bottom: 1px solid #cae1ff;

      .table__heading {
        margin: 2px 0;
        line-height: 1;
      }
    }

    .table__heading[colspan="2"],
    .table__item[colspan="2"] {
      flex-shrink: 0;
      flex-grow: 2;
      padding-right: 24px !important;
    }
  }

  &__heading {
    text-align: left;
    font-weight: 400;
    color: #3a7bd5;
    margin-top: $base--8;
    margin-bottom: $base--8;
    flex: 1;
  }

  &__body {
    width: fit-content;
    min-width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $base--8 * 2;
    padding-bottom: $base--8 * 2;

    .greeked-text {
      height: 16px;
      display: inline-block;
      width: 100%;
      background: linear-gradient(
        277deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.08)
      );
      background-size: 400% 400%;
      transition-duration: 1s;
      animation: greekedText 1s ease infinite;
    }
  }

  &__print {
    background: #4a90e2;
    color: white;
    font-size: 12px;
    padding: $base--8/2 $base--8 * 3;
  }

  &__sum {
    // width: 135px;
    white-space: nowrap;
    text-align: right;
  }

  &__item {
    flex: 1;

    &--amount {
      span {
        width: 100px;
      }
    }

    &--receipt {
      flex: 1;
    }

    &--card {
      flex: 2;
    }

    &--print {
      margin-left: $base--8 * 6;
    }

    svg {
      fill: #2f80ed;
      flex-shrink: 0;
    }
  }

  .sort-icon {
    height: 1em;
    display: inline-block;
    margin-left: 2px;
  }
}

.table.sticky {
  position: relative;
  z-index: 0;

  max-height: 90vh;

  *[data-sticky-td] {
    background: #fff;
    border-right: 1px dashed #cae1ff;

    &.table__heading {
      border-bottom: 2px solid #4a90e2;
      margin-bottom: -2px;
    }
  }

  .table__header {
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: #fff;
    &:nth-child(even) {
      top: 38px;
    }
  }
  .table__header ~ .table__header .table__heading[data-sticky-td] {
    border-bottom: 1px solid #cae1ff;
    margin-bottom: -1px;
  }

  .table__row:nth-child(even) *[data-sticky-td] {
    background: #f6f9fd;
  }

  .financial-table-footer {
    position: sticky;
    bottom: 0;
    z-index: 4;
  }
}

@keyframes greekedText {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 51%;
  }

  100% {
    background-position: 0% 50%;
  }
}

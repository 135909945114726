//	-------------------------------------------------------------
//
//	GENERAL LAYOUT
//
//	-------------------------------------------------------------

// main layout for the donation portal
.donation_portal {
  width: 100%;
  min-width: rem-calc(320);
  background: $delta;
  &__container {
    width: 100%;
    max-width: rem-calc(1140); // 960 + 40 (gutters)
    margin: 0 auto;
    padding: 0 rem-calc(20);
    @include clearfix;
  }
  &__main {
    @include span(8 of 12);
  }
  &__aside {
    @include span(4 of 12);
    margin-right: 0;
  }
  // breakpoints / media queries
  @include bp(medium) {
    padding-top: 0;
    &__main {
      width: 100%;
      margin: 0;
    }
    &__aside {
      display: none;
    }
  }
}

// panel styles
.panel {
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.07);
  border-radius: 3px;

  &__heading {
    color: $alpha;
  }
  // .panel__content - used only on thanks page after donating
  &__content {
    width: 100%;
    padding: rem-calc(50);
    border-bottom: 1px solid $kappa;
    &--last {
      border-bottom: 0;
      padding: 24px rem-calc(50);
    }
  }
  // .panel__foot - used on none form pages
  &__foot {
    width: 100%;
    padding: rem-calc(40) rem-calc(50);
  }
  // breakpoints / media queries
  @include bp(small) {
    &__content {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
    &__foot {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
  }
}

// this is important for the use of tabs.js
div[data-content-item] {
  display: none;
  // display: block; // to help with styling / so you can see sections of the form on one page
  &.js__selected {
    display: block;
  }
}

// specific donation portal push classes
// these are mostly used on .form__rows to help space out form elements
.donation_portal,
.remodal {
  .push_b1 {
    margin-bottom: rem-calc(15);
  }
  .push_b2 {
    margin-bottom: rem-calc(25);
  }
  .push_b3 {
    margin-bottom: rem-calc(35);
  }
}

.remodal {
  max-height: 800px;
}

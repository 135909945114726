//	----------------------------------------------------------------------------
//
//	BROWSER PREFIX MIXINS
//
//	----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// 1. TRANSFORMS
// -----------------------------------------------------------------------------

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
            transform: $transforms;
}

@mixin transform-origin($transforms) {
	   -moz-transform-origin: $transforms;
	     -o-transform-origin: $transforms;
	    -ms-transform-origin: $transforms;
	-webkit-transform-origin: $transforms;
            transform-origin: $transforms;
}

//  ----------------------------------------------------------------------------
//	2. FLEXBOX MIXINS
//	----------------------------------------------------------------------------

// Display ---------------------------------------------------------------------

// Flex
@mixin flex-box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

// Inline Flex
@mixin inline-flex {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

// Flex Direction --------------------------------------------------------------

// Values: row | row-reverse | column | column-reverse
// Default: row

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}


// Flexbox Wrap ----------------------------------------------------------------

// Values: nowrap | wrap | wrap-reverse
// Default: nowrap

@mixin flex-wrap($value: nowrap) {
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else { 
		-ms-flex-wrap: $value; 
	}
	flex-wrap: $value;
}


// Flexbox Flow ----------------------------------------------------------------

// Values: <flex-direction> | <flex-wrap> 
// Default: row nowrap

@mixin flex-flow($values: (row nowrap)) {
	// No Webkit Box fallback.
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}


// Flexbox Order ---------------------------------------------------------------

// Default: 0

@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}


// Flexbox Grow ----------------------------------------------------------------

// Default: 0

@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int;
}


// Flexbox Shrink --------------------------------------------------------------

// Default: 1

@mixin flex-shrink($int: 1) {
	-webkit-flex-shrink: $int;
	-moz-flex-shrink: $int;
	-ms-flex-negative: $int;
	flex-shrink: $int;
}


// Flexbox Basis ---------------------------------------------------------------

// Default: auto

@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	-moz-flex-basis: $value;
	-ms-flex-preferred-size: $value;
	flex-basis: $value;
}


// Flex ------------------------------------------------------------------------

// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: 1

@mixin flex($fg: 1, $fs: null, $fb: null) {
    
	// Set a variable to be used by box-flex properties
	$fg-boxflex: $fg;

	// Box-Flex only supports a flex-grow value so let's grab the
	// first item in the list and just return that.
	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}

	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;
}


// Flexbox Justify Content -----------------------------------------------------

// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;		
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}


// Flexbox Align Items ---------------------------------------------------------

// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch

@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}


// Flexbox Align Self ----------------------------------------------------------

// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
	-webkit-align-self: $value;
	-moz-align-self: $value;
	@if $value == flex-start {
		-ms-flex-item-align: start;
	} @else if $value == flex-end {
		-ms-flex-item-align: end;
	} @else {
		-ms-flex-item-align: $value;
	}
	align-self: $value;
}


// Flexbox Align Content -------------------------------------------------------

// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch

@mixin align-content($value: stretch) {
	-webkit-align-content: $value;
	-moz-align-content: $value;
	@if $value == flex-start {
		-ms-flex-line-pack: start;
	} @else if $value == flex-end {
		-ms-flex-line-pack: end;
	} @else {
		-ms-flex-line-pack: $value;
	}
	align-content: $value;
}

// -----------------------------------------------------------------------------
// 3. ANIMATIONS
// -----------------------------------------------------------------------------

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};      
}

// Shameful overrides

.donation-form {

	.donation-form__wrapper {
		display: flex;

		.donation-form__content {
		    flex: 1 1;
			margin-right: 0;
			cursor: default;
			border-radius: 3px 0px 0px 3px;
		}

		.donation-form__summary {
			flex: 0 1 335px;
			padding: 32px;
			background: #EBEEF2;
			border-radius: 0px 3px 3px 0px;
			height: initial;
			cursor: default;

			@media (max-width: 915px) {
				display: none;
			}
		}

		.aside_details {
			margin-top: 32px;
		}

	}
}

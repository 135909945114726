@import "../../abstracts/all";

small.input__error,
.input__error {
  transition: $default-transition;
  position: absolute;
  top: calc(100% + 8px);
  font-size: 12px;
  padding: $base--8/2 $base--8;
  // margin-top:$base--8*2;
  // margin-bottom:$base--8*2;
  // position:relative;
  display: inline-block;
  border-radius: $radius;
  width: 100%;
  border: 1px solid $error;
  color: white;
  background: $error;
  font-weight: 600;
  line-height: 18px;
  z-index: 10;
  min-width: 120px;
  max-width: 240px;
  left: 0;
  // opacity: 0;
    // visibility: hidden;
  transform: translateY(8px);

  &:after {
    $tooltip-size: 6px;

    @include triangle("up", $tooltip-size, $error);

    left: 16px;
    top: -$tooltip-size;
  }
}

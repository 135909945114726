//	-------------------------------------------------------------
//
//	CUSTOM SCSS STYLES FOR CHOSEN SELECT MENU
// 	https://github.com/harvesthq/chosen/blob/master/sass/chosen.scss
//
//	-------------------------------------------------------------

// HERE FOR STYLING REFERENCE
// <div class="chosen-container chosen-container-single chosen-container-single-nosearch" style="width: 100%;" title="">
// 	<a class="chosen-single chosen-default">
// 		<span>Other</span>
// 		<div><b></b></div>
// 	</a>
// 	<div class="chosen-drop">
// 		<div class="chosen-search">
// 			<input type="text" autocomplete="off" readonly="">
// 		</div>
// 		<ul class="chosen-results">
// 			<li class="active-result" data-option-array-index="1">Weekly</li>
// 			<li class="active-result" data-option-array-index="2">fortnightly</li>
// 			<li class="active-result" data-option-array-index="3">Half Yearly</li>
// 			<li class="active-result" data-option-array-index="4">Annually</li>
// 		</ul>
// 	</div>
// </div>

// NOTE: Any CSS that is commented out is because we are currently not using this part of the chosen functionality

// $chosen-sprite: '';
// $chosen-sprite-retina: '';

/* @group Base */
.chosen-container {
	position: relative;
	display: block;
	font-size: rem-calc(13);
	user-select: none;
	.chosen-drop {
		position: absolute;
		top: calc(100% + #{rem-calc(5)});
		z-index: 1010;
		width: 100%;	
		clip: rect(0,0,0,0);
		@include card;
	}
	&.chosen-with-drop .chosen-drop {
		clip: auto;
	}
	a {
		cursor: pointer;
	}
	// .search-choice, 
	// .chosen-single {
	// 	.group-name {
	// 		margin-right: 4px;
	// 		overflow: hidden;
	// 		white-space: nowrap;
	// 		text-overflow: ellipsis;
	// 		font-weight: normal;
	// 		color: #999999;
	// 		&:after {
	// 			content: ":";
	// 			padding-left: 2px;
	// 			vertical-align: top;
	// 		}
	// 	}
	// }
}
/* @end */

/* @group Single Chosen */
.chosen-container-single {
	// the default chosen styles

	.chosen-single {
		position: relative;
		@include flex-box;
		@include align-items(center);
		@include justify-content(center);
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
		height: rem-calc(38); // equal to the height of buttons
		font-size: rem-calc(13);
		text-align: center;
		background: $psi;
		transition: color $transition, background $transition;
		border-radius: rem-calc(38);
		// active state
		cursor: pointer;
		color: $alpha;
		&:hover {
			color: $alpha;
		}
	}

	.chosen-default {
		// inactive state
		color: $gamma;
		background: transparent;
		border: 1px solid $psi;
	}

	.chosen-single span {
		$icon_size: rem-calc(24);
		position: relative;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: $icon_size;
		padding: 0 $icon_size + rem-calc(5);
		// icon 
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			width: $icon_size;
			height: $icon_size;
			font-family: $icon;
			font-size: $icon_size;
			line-height: $icon_size;
			content: "keyboard_arrow_down";
		}
	}
	// .chosen-single-with-deselect span {
	// 	margin-right: 38px;
	// }
	// .chosen-single abbr {
	// 	position: absolute;
	// 	top: 6px;
	// 	right: 26px;
	// 	display: block;
	// 	width: 12px;
	// 	height: 12px;
	// 	background: $chosen-sprite -42px 1px no-repeat;
	// 	font-size: 1px;
	// 	&:hover {
	//   		background-position: -42px -10px;
	// 	}
	// }
	// &.chosen-disabled .chosen-single abbr:hover {
	// 	background-position: -42px -10px;
	// }

	.chosen-single div {
		// This was used to display the arrow icon
		// now using :after on the .chosen-single span for better palcement
		display: none;
		// b {
		// }
	}
	// .chosen-search {
	// 	position: relative;
	// 	z-index: 1010;
	// 	margin: 0;
	// 	padding: 3px 4px;
	// 	white-space: nowrap;
	// 	input[type="text"] {
	// 		margin: 1px 0;
	// 		padding: 4px 20px 4px 5px;
	// 		width: 100%;
	// 		height: auto;
	// 		outline: 0;
	// 		border: 1px solid #aaa;
	// 		background: $chosen-sprite no-repeat 100% -20px;
	// 		font-size: 1em;
	// 		font-family: sans-serif;
	// 		line-height: normal;
	// 		border-radius: 0;
	// 	}
	// }
	.chosen-drop {
		// margin-top: -1px;
		// border-radius: 0 0 4px 4px;
		background-clip: padding-box;
	}
	&.chosen-container-single-nosearch .chosen-search {
		position: absolute;
		clip: rect(0,0,0,0);
	}
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 240px;
	-webkit-overflow-scrolling: touch;
	text-align: center;
	@include listreset;
	padding: rem-calc(5);
	li {
		display: none;
		padding: rem-calc(8) rem-calc(12);
		word-wrap: break-word;
		-webkit-touch-callout: none;
		color: $gamma;
		background: transparent;
		transition: background $transition, color $transition;
		&.active-result {
			display: list-item;
			cursor: pointer;
		}
		// &.disabled-result {
		// 	display: list-item;
		// 	color: #ccc;
		// 	cursor: default;
		// }
		&.highlighted {
			background: $psi;	
			color: $alpha;
		}
		// &.no-results {
		// 	color: #777;
		// 	display: list-item;
		// 	background: #f4f4f4;
		// }
		// &.group-result {
		// 	display: list-item;
		// 	font-weight: bold;
		// 	cursor: default;
		// }
		// &.group-option {
		// 	padding-left: 15px;
		// }
		// em {
		// 	font-style: normal;
		// 	text-decoration: underline;
		// }
	}
}
/* @end */

/* @group Multi Chosen */
// .chosen-container-multi{
// 	.chosen-choices {
// 		position: relative;
// 		overflow: hidden;
// 		margin: 0;
// 		padding: 0 5px;
// 		width: 100%;
// 		height: auto;
// 		border: 1px solid #aaa;
// 		background-color: #fff;
// 		background-image: linear-gradient(#eee 1%, #fff 15%);
// 		cursor: text;
// 	}
// 	.chosen-choices li {
// 		float: left;
// 		list-style: none;
// 		&.search-field {
// 			margin: 0;
// 			padding: 0;
// 			white-space: nowrap;
// 			input[type="text"] {
// 				margin: 1px 0;
// 				padding: 0;
// 				height: 25px;
// 				outline: 0;
// 				border: 0 !important;
// 				background: transparent !important;
// 				box-shadow: none;
// 				color: #999;
// 				font-size: 100%;
// 				font-family: sans-serif;
// 				line-height: normal;
// 				border-radius: 0;
// 			}
// 		}
// 		&.search-choice {
// 			position: relative;
// 			margin: 3px 5px 3px 0;
// 			padding: 3px 20px 3px 5px;
// 			border: 1px solid #aaa;
// 			max-width: 100%;
// 			border-radius: 3px;
// 			background-color: #eeeeee;
// 			background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
// 			background-size: 100% 19px;
// 			background-repeat: repeat-x;
// 			background-clip: padding-box;
// 			box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(#000,.05);
// 			color: #333;
// 			line-height: 13px;
// 			cursor: default;
// 			span {
// 				word-wrap: break-word;
// 			}
// 			.search-choice-close {
// 				position: absolute;
// 				top: 4px;
// 				right: 3px;
// 				display: block;
// 				width: 12px;
// 				height: 12px;
// 				background: $chosen-sprite -42px 1px no-repeat;
// 				font-size: 1px;
// 				&:hover {
// 					background-position: -42px -10px;
// 				}
// 			}
// 		}
// 		&.search-choice-disabled {
// 			padding-right: 5px;
// 			border: 1px solid #ccc;
// 			background-color: #e4e4e4;
// 			background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
// 			color: #666;
// 		}
// 		&.search-choice-focus {
// 			background: #d4d4d4;
// 			.search-choice-close {
// 				background-position: -42px -10px;
// 			}
// 		}
// 	}
// 	.chosen-results {
// 		margin: 0;
// 		padding: 0;
// 	}
// 	.chosen-drop .result-selected {
// 		display: list-item;
// 		color: #ccc;
// 		cursor: default;
// 	}
// }
/* @end */

/* @group Active  */
.chosen-container-active {
	.chosen-single {
		color: $alpha;
	}
	&.chosen-with-drop {
		.chosen-single {
			color: $alpha;
		}
		// .chosen-single div {
		// 	b {
		// 	}
		// }
	}
	// .chosen-choices {
	// 	border: 1px solid #5897fb;
	// 	box-shadow: 0 0 5px rgba(#000,.3);
	// 	li.search-field input[type="text"] {
	// 		color: #222 !important;
	// 	}	
	// }
}
/* @end */

/* @group Disabled Support */
// .chosen-disabled {
// 	opacity: 0.5 !important;
// 	cursor: default;
// 	.chosen-single {
// 		cursor: default;
// 	}
// 	.chosen-choices .search-choice .search-choice-close {
// 		cursor: default;
// 	}
// }
/* @end */

/* @group Right to Left */
// .chosen-rtl {
// 	text-align: right;
// 	.chosen-single {
// 		overflow: visible;
// 		padding: 0 8px 0 0;
// 	}
// 	.chosen-single span {
// 		margin-right: 0;
// 		margin-left: 26px;
// 		direction: rtl;
// 	}
// 	.chosen-single-with-deselect span {
// 		margin-left: 38px;
// 	}
// 	.chosen-single div {
// 		right: auto;
// 		left: 3px;
// 	}
// 	.chosen-single abbr {
// 		right: auto;
// 		left: 26px;
// 	}
// 	.chosen-choices li {
// 		float: right;
// 		&.search-field input[type="text"] {
// 			direction: rtl;
// 		}
// 		&.search-choice {
// 			margin: 3px 5px 3px 0;
// 			padding: 3px 5px 3px 19px;
// 			.search-choice-close {
// 				right: auto;
// 				left: 4px;
// 			}
// 		}
// 	}
// 	&.chosen-container-single .chosen-results {
// 		margin: 0 0 4px 4px;
// 		padding: 0 4px 0 0;
// 	}
// 	.chosen-results li.group-option {
// 		padding-right: 15px;
// 		padding-left: 0;
// 	}
// 	&.chosen-container-active.chosen-with-drop .chosen-single div {
// 		border-right: none;
// 	}
// 	.chosen-search input[type="text"] {
// 		padding: 4px 5px 4px 20px;
// 		background: $chosen-sprite no-repeat -30px -20px;
// 		direction: rtl;
// 	}
// 	&.chosen-container-single {
// 		.chosen-single div b {
// 			background-position: 6px 2px;
// 		}
// 		&.chosen-with-drop {
// 			.chosen-single div b {
// 				background-position: -12px 2px;
// 			}
// 		}
// 	}
// }
/* @end */

/* @group Retina compatibility */
// @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
// 	.chosen-rtl .chosen-search input[type="text"],
// 	.chosen-container-single .chosen-single abbr,
// 	.chosen-container-single .chosen-single div b,
// 	.chosen-container-single .chosen-search input[type="text"],
// 	.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
// 	.chosen-container .chosen-results-scroll-down span,
// 	.chosen-container .chosen-results-scroll-up span {
// 		background-image: $chosen-sprite-retina !important;
// 		background-size: 52px 37px !important;
// 		background-repeat: no-repeat !important;
// 	}
// }
/* @end */
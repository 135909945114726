//	-------------------------------------------------------------
//
//	Accordion
//
//	------------------------------------------------------------

$gutter: gutter();
$base-grid: rem-calc(10);

.accordion {
	width: 100%;
	margin-bottom: rem-calc(44);
	&__question {
		padding: ($base-grid*1.5) 0;
		border-bottom: 1px solid $kappa;
		&:last-child {
			border: none;
		}
	}
	&__header {
		cursor: pointer;
		@include flex-box;
		@include justify-content(space-between);
		@include align-items(center);
		h5 {
			font-size: rem-calc(16);
			transition: color $transition;
		}
		i {
			margin-left: $base-grid;
			transition: color $transition, transform $transition;
		}
		&:hover {
			color: $alpha;
		}
		&.is-active {
			color: $alpha;
			i {
				@include transform(rotate(45deg));
			}
		}
	}
	&__content {
		// display: none;
		margin-top: $base-grid;
		p {
			margin-bottom: rem-calc(10);
		}
	}
}

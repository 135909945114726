//	-------------------------------------------------------------
//
//	MAIN NAVIGATION
//
//	-------------------------------------------------------------

.navigation {
  width: 100%;
  position: relative;
  height: $navigation_height;
  // bottom gradient
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem-calc(2);
    background-image: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);
  }
  &__inner {
    @include flex-box;
    @include flex-wrap(nowrap);
    @include justify-content(space-around);
    width: 100%;
    margin: 0;
    padding: rem-calc(25) rem-calc(20);
    list-style-type: none;
  }
  &__item {
    padding: 0 rem-calc(10);
  }
  &__link {
    @include flex-box;
    @include align-items(center);
    @include justify-content(center);
    text-align: center;
    &:hover,
    &.js__selected {
      .navigation__number {
        color: $alpha;
        border-color: $alpha;
      }
      .navigation__title {
        color: $alpha;
      }
    }
    &.js__selected {
      cursor: default;
    }
  }
  &__number {
    display: block;
    width: rem-calc(22);
    height: rem-calc(22);
    color: $gamma;
    font-size: rem-calc(15);
    line-height: rem-calc(20);
    border: 1px solid $beta;
    border-radius: 100%;
    transition: color $transition, border-color $transition;
  }
  &__title {
    color: $gamma;
    font-size: rem-calc(15);
    margin-left: rem-calc(10);
    transition: color $transition;
  }
}

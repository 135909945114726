@import "../../abstracts/all";

.cc-expiry {
  right: -14px;
  width: 300px;

  &:before {
    @include triangle("up", 6px, white);

    top: -6px;
    left: $base--8 * 2;
  }

  &__list {
    padding: 0;
    margin-left: $base--8 * 2;
    font-size: 14px;
    white-space: nowrap;
  }

  &__item {
    margin-bottom: $base--8/2;
  }

  ~ .modal {
    cursor: pointer;
  }
}

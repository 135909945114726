//	-------------------------------------------------------------
//
//	CONFIRM PANEL VIEW
//
//	-------------------------------------------------------------

.confirm_panel {
	width: 100%;
	height: 0;
	border-radius: 5px;
	overflow: hidden;
	transition: height .2s ease-out;
	&__inner {
		width: 100%;
		padding-bottom: rem-calc(18);
	}
	&__content {
		width: 100%;
		padding: rem-calc(20);
		opacity: 0;
		background:	rgba($psi,0.50);
		transition: opacity .2s ease-out;
		&.js__fade_in {
			opacity: 1; 
		}
	}
	&__title {
		position: relative;
		margin-bottom: rem-calc(10);
		h4 {
			margin: 0;
		}
	}
	&__edit_link {
		position: absolute;
		top: 50%;
		left: 135px;
		font-size: rem-calc(12);
		font-weight: $font_bold;
		@include transform(translateY(-50%));
		@include bp(small) {
			left: auto;
			right: 0;
		}
	}
}
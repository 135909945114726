// -------------------------------------------------------------
//
//	SCSS Colours
//
//	------------------------------------------------------------

$body-font-color: #566d77;
$body-color--dark: #5a5a62;
$body-color--light: #959ca0;
$body-color--lightest: #c9cdcf;
$body-background-light: #e4eefb;

$blue--mid: #1e88e5;

$error: #f64662;

$border--lightest: #f5f5f5;
$border--light: #eef1f3;
$border--mid: #acb5bc;

$disabled-background: #f1f3f3;
$disabled-color: #c9cdcf;

$alpha: #4a90e2; // blue
$beta: #bebec6; // grey
$gamma: #5a5a62; // black
$delta: #f2f2f2; // light grey - background of tabs, radio buttons
$delta_light: #f9f9f9;

$body_font_color: $gamma;

$kappa: #e6e6ed; // grey - used for borders
$psi: #e4eefb; // lighter version of alpha
$omega: #8c8c94; // mid grey - used search button, form notes, btn_sec

$affirmative: #50c736;
$warning: #ff3838;
$alert: #c70004;

$select_color: rgba(0, 0, 0, 0.2);

:selection {
  background-color: $select_color;
}

:moz-selection {
  background-color: $select_color;
}

:webkit-selection {
  background-color: $select_color;
}

@import "../abstracts/variables";
@import "../abstracts/colours";

@mixin spinner($size, $color: $blue--mid) {
  @keyframes spinner {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $size;
  height: $size;
  border-radius: 50%;
  border-top: 2px solid $blue--mid;
  border-top-color: $color;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  transform: translate(-50%, -50%) rotate(0);
}

.spinner {
  &:before {
    @include spinner(24px, $blue--mid);
  }

  .loading-programs & {
    // Temporarily fixed while waiting on header containers etc.

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;

    &:before {
      $size: 72px;
      $border-size: 4px;

      width: $size;
      height: $size;
      border-top: $border-size solid $blue--mid;
      border-right: $border-size solid transparent;
    }
  }
}

.spinner--inline {
  &:before {
    @include spinner(15px, $blue--mid);

    width: 15px;
    height: 15px;
    border-top: 1px solid $blue--mid;
    border-right: 1px solid transparent;
  }

  position: relative;
  height: 16px;
  width: 100%;
}

//	-------------------------------------------------------------
//
// 	SASS / SUSY Config
//
//	-------------------------------------------------------------

// Configure some properties for our grid.
$total-columns: 	12; 			// The number of columns in your grid
$column-gutter:		0.75; 			// The size of a gutter in relation to a single column
// $column-width:		rem-calc(80); 	// The size of a gutter in relation to a single column
// $container-width: 	rem-calc(1440);	// Sets a max-width for the container

// Create a global Susy layout using our config.
$susy: (
	columns: 			$total-columns,
	// column-width: 		$column-width,
	gutters: 			$column-gutter,
	// gutter-position: 	inside-static, // Use this to mimic Foundation's gutter settings
	// container: 			$container-width,
	// Hide/show the grid for debugging
	debug: (
	    image: hide,
	    color: blue,
	    output: overlay,
	    toggle: top right,
  	),
);

// Settings for replicating Foundation's
// Unused at V1, to be implemented in a later version
// These shouldnt need to be changed.
// $fdgrid: (
//  	columns: 			$total-columns,
//  	column-width: 		$column-width,
//  	gutters: 			$column-gutter,
//  	gutter-position: 	inside-static, // Use this to mimic Foundation's gutter settings
//  	container: 			$container-width,
// );

//	-------------------------------------------------------------
//
//  Media Queries
//
//	-------------------------------------------------------------

// AFES specific media queries
// using this for more control of the layout at choosen screen sizes
@mixin bp($point) {
    // $bp_large: "(max-width: 1280px)";
    $bp_medium: "(max-width: 900px)";
    $bp_small: "(max-width: 685px)";
    // $bp_x_small: "(max-width: 400px)";
    // @if $point == large {
    //     @media #{$bp_large} { 
    //         @content; 
    //     }
    // }
    @if $point == medium {
    // @else if $point == medium {
        @media #{$bp_medium} { 
            @content; 
        }
    }
    @else if $point == small {
        @media #{$bp_small} {
            @content;
     	}
    }
    // @else if $point == x_small {
    //     @media #{$bp_x_small} {
    //         @content;
    //     }
    // }
}


// Specify our ranges
$small-range: 		(0, 40em);
$medium-range: 		(40.063em, 64em);
$large-range: 		(64.063em, 90em);
$xlarge-range: 		(90.063em, 120em);
$xxlarge-range: 	(120.063em);


// Configure which media queries to include
$include-small: 	true;
$include-medium: 	true;
$include-large: 	true;
$include-xlarge: 	false;
$include-xxlarge: 	false;

$screen: 		'only screen';

$small-up: 		$screen;
$medium-up: 	"#{$screen} and (min-width:#{lower-bound($medium-range)})";
$large-up: 		"#{$screen} and (min-width:#{lower-bound($large-range)})";
$xlarge-up: 	"#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xxlarge-up:	"#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";

// TODO: May remove these if they prove unnecessary, I've never used them...
$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";


//	-------------------------------------------------------------
//
// 	Global Site Variables
//
//	-------------------------------------------------------------

$transition: 175ms ease-out;
$card_box_shadow: 0 0 4px rgba(0,0,0,0.20);
$global_box_shadow: 0 0 4px rgba(0,0,0,0.20); // used on form inputs & card styles
$navigation_height: rem-calc(72);


//	-------------------------------------------------------------
//
// 	Global Style Mixins
//
//	-------------------------------------------------------------

@mixin card {
	border-radius: rem-calc(3);
	background: white;
	box-shadow: $global_box_shadow;
	border: 1px solid $beta;
}

@mixin notify {
	color: $alpha;
	background: $psi;
	border: 1px solid lighten($alpha,25%);
	border-radius: rem-calc(3);
}

//	-------------------------------------------------------------
//
// 	Other Helpers 
//
//	-------------------------------------------------------------

// clearfix
@mixin clearfix {
	&:after {
		content: "";
  		display: table;
  		clear: both;
  	}
}

// border box sizing
@include border-box-sizing;

// visuallyhidden elements - taken from html5boilerplate
// used when you want elements to be hidden visually but available to screenreaders
// it's also good to use for styling radio buttons and checkboxes 
// as some browers don't like it when you add 'display:none' or 'visibility:hidden' to input elements
// but still want to leverage the 'checked' state for css
@mixin visuallyhidden {	
	border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

// placeholder mixin
// add's various placeholder prefixes for cross browser styling
@mixin placeholder {
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

// stretch mixin
// generally used when you want a element inside a relative 
// positioned element to fill the entire space available
@mixin stretch {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// list reset
// for reseting list styles
@mixin listreset {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
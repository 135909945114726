//	-------------------------------------------------------------
//
//	OVERRIDES & TEMPORARY STYLES
//
//	-------------------------------------------------------------

// admin-session
.cms_edit {
	display: none !important;
}

// overides for the login_overlay_view to match remodal login as much as possible
#overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background: rgba($alpha,0.25);
}

#session_timeout {
	position: absolute;
	top: 50%!important;
	left: 50%;
	width: 100%;
	max-width: rem-calc(320);
	height: auto;
	z-index: 10000;
	@include transition(transform 0.2s ease-out);
	@include transform(scale(1) translate(-50%,-50%));
	&.vanished {
		@include transform(scale(0));
	}
	.auth_wrapper {
		position: relative;
		color: $gamma;
    	background: white;
    	box-shadow: 0 0 24px 0 rgba(0,0,0,0.12);
	    &:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: rem-calc(2);
			background-image: linear-gradient(-167deg, #00D2FF 0%, #3A7BD5 100%);
		}
	}
	.form_section {
		padding: rem-calc(20);
		border-bottom: none;
	}
}

@import "../abstracts/all";

.reports {
  &__wrap {
    padding: $base--8 * 2;
    background: #fff;
    min-height: 100vh;

    @media screen and (min-width: 768px) {
      padding: $base--8 * 4;
    }

    @media screen and (min-width: 1024px) {
      padding: $base--8 * 6;
    }
  }

  &__summary {
    font-size: 12px;
  }

  label .label-text {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3a7bd5;
    margin-bottom: $base--8 * 2;
  }

  .label-with-meta {
    margin-bottom: 6px;

    @media screen and (min-width: 600px) {
      display: flex;
      justify-content: space-between;
    }

    label {
      margin-bottom: 0;
      flex-basis: auto;
    }

    small {
      text-align: right;
      opacity: 0.7;
      font-size: 12px;
    }
  }

  hr {
    border: 1px solid rgba(86, 86, 86, 0.2);
    height: 0;
    margin: $base--8 * 3 0 $base--8 * 4 0;
  }

  h2 {
    font-weight: 600;
  }

  .Select {
    width: 100%;

    .Select-placeholder {
      line-height: 38px;
    }
  }

  .table__body {
    opacity: 1;
    transition-duration: 1s;
    transition-property: opacity;
  }

  .table__row {
    font-size: 13px;
    line-height: 16px;
    padding-top: 0;
    padding-bottom: 0;
    align-items: stretch;

    &:focus {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      border: 1px solid #4a90e2;
      outline: none;
    }
  }

  .financial-table-footer {
    width: 100%;
    background: linear-gradient(0deg, #4279bd 0%, #458de3 100%) !important;
    color: #fff;
    transition-property: opacity;
    transition-duration: 1s;
  }

  .table.sticky .financial-table-footer *[data-sticky-td] {
    background: linear-gradient(0deg, #4279bd 0%, #458de3 100%);
  }

  .table__row:nth-child(even) {
    background: #f6f9fd;
  }

  .table__heading {
    line-height: 20px;
    margin: 0;
  }

  .table__heading,
  .table__heading > div,
  .table__item,
  .table__item > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .table__item,
  .table__heading {
    padding: 8px $base--8;
    min-width: 155px;

    &:first-child {
      position: relative;
      min-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      justify-content: space-between;
      align-items: center;
      padding: 8px $base--8 * 2;
      flex-grow: 2;

      svg {
        position: absolute;
        right: 4px;
        top: 9px;
      }
    }

    &:last-child {
      padding-right: $base--8 * 2;
    }
  }

  .table--loading {
    overflow-y: hidden;
    opacity: 0.65 !important;
    cursor: wait;
    * {
      pointer-events: none;
    }
  }

  .table--received-support .table__item,
  .table--received-support .table__header ~ .table__header .table__heading {
    width: 80px;
    min-width: 80px;
    text-align: right;
    line-height: 20px;
    &:first-child {
      min-width: 200px;
      text-align: left;
    }
  }

  .table--received-support .table__heading--lastname {
    flex-grow: 0;
    max-width: 200px;
    min-width: 200px !important;
    text-align: left;
  }

  .table__item.clickable {
    cursor: pointer;
  }

  @media screen and (min-width: 960px) {
    .table__item,
    .table__heading {
      min-width: 110px;

      &:first-child {
        min-width: 240px;
      }
    }

    .programme-select {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      p {
        flex-basis: 35%;
        text-align: right;
        line-height: 1;
        height: 38px;
      }

      & > label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-basis: 60%;
        margin-bottom: 0;

        .label-text {
          white-space: nowrap;
          margin-right: 8px;
        }

        .Select {
          max-width: 500px;
        }
      }

      hr {
        flex-basis: 100%;
      }
    }

    .staff-report-filters {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 32px 0 16px;

      * {
        margin-bottom: 0;
      }

      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;

        .label-text {
          white-space: nowrap;
          margin-right: 32px;
          margin-bottom: 0;
        }
      }

      .staff-report-filters__type {
        width: 240px;
      }

      .staff-report-filters__search {
        margin-left: auto;
        flex-basis: 240px;
        font-size: 14px;
      }
    }
  }

  /*
   * Column Specific Styles...
   */
  .table__heading--amount,
  .table__heading--amount_last_deposit {
    text-align: right;
  }

  .table__heading.table__heading--last_deposit {
    flex-grow: 1.5;
    min-width: 200px;
  }

  .table__heading.table__heading--date_last_deposit,
  .table__heading.table__heading--amount_last_deposit {
    margin: 0;
    padding-top: 0;
    padding-bottom: 5px;
  }

  .table__heading.table__heading--date_last_deposit,
  .table__heading.table__heading--amount_last_deposit,
  .table__item--date_last_deposit,
  .table__item--amount_last_deposit {
    flex-grow: 0.75;
    min-width: 100px;
  }

  .table__item--date_last_deposit,
  .table__item--amount_last_deposit,
  .table__heading--amount_last_deposit,
  .table__heading--date_last_deposit,
  .table__heading--last_deposit {
    background: #f7f7f7;
  }

  .table__row:nth-child(even) {
    .table__item--date_last_deposit,
    .table__item--amount_last_deposit {
      background: mix(#000, #f6f9fd, 3%);
    }
  }

  .table__item--date_last_deposit,
  .table__heading--last_deposit,
  .table__heading--date_last_deposit {
    border-left: 16px solid #fff;
    position: relative;
    overflow: visible;

    &:before {
      content: "";
      z-index: 2;
      display: block;
      background: #fff;
      position: absolute;
      right: 100%;
      width: 16px;
      top: -4px;
      height: 8px;
    }
  }
}

.progress {
  height: 30px;
  width: 100%;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  position: relative;

  &__value {
    background: #4a90e2;
    transition-duration: 1s;
    transition-property: min-width width background;
    height: 100%;
    min-width: 50px;
    color: #fff;
    line-height: 28px;
    text-align: center;
    max-width: 100%;
    border-radius: 2px;
    width: 100%;
  }

  &-loading .progress__value {
    opacity: 0.5;
    min-width: 100%;
    animation: animate-stripes 2s linear infinite;
    background: linear-gradient(
        -45deg,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      linear-gradient(rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
      linear-gradient(to right, #fff, #fff);
    background-size: 54px 28px, 100% 100%, 100% 100%;
  }
}

@keyframes animate-stripes {
  100% {
    background-position: -108px 0;
  }
}

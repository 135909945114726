@import "../components/forms/inputs";
@import "../components/forms/selects";
@import "../components/forms/labels";
@import "../components/forms/fieldsets";
@import "../components/forms/validation";
@import "../components/forms/legends";
@import "../components/buttons";
@import "../components/spinners";

.form {
  position: relative;

  [data-required] {
    background: red;
  }

  [data-required="required"] {
    background: red;
    border: 1px solid purple;

    label {
      position: relative;
      display: inline-block;

      &:after {
        content: "*";
        color: red;
        position: absolute;
        right: -$base--8;
      }
    }
  }

  @include respond-to("bp-small", max) {
    &:after {
      display: none;
    }
  }

  &__block {
    &:last-child {
      padding-bottom: $base--8 * 2;
    }
  }

  &__wrapper {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__row {
    &:not(:last-of-type) {
      margin-bottom: $base--8 * 2;
      // margin-top: $gutter-spacing;
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    &__mb0 {
      margin-bottom: 0 !important;
    }

    &__mb2x {
      margin-bottom: $gutter-spacing * 2;
    }

    // &:nth-last-child(2) {
    // 	margin-bottom:0;
    // }
    > div {
      position: relative;

      // width: 100%;
    }

    @include respond-to("small-down", max) {
      flex-wrap: wrap;

      > div {
        flex: 1 1 100%;
        margin-right: 0;

        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: $gutter--12 * 2;
        }
      }
    }
  }

  &__finalise-changes {
    justify-content: flex-end;
    // @include respond-to('small-down', max) {
    // 	position: relative;
    // 	height: 96px;
    // }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__alert {
    border: 1px solid $blue--mid;
    background: rgba($blue--mid, 0.1);
    color: $blue--mid;
    padding: $gutter--12;
    margin-bottom: $gutter-spacing;
    font-size: 12px;

    &:only-child {
      // to override base settings which could effect other areas.

      margin-bottom: $gutter-spacing;
    }

    &.error {
      color: rgba($error, 0.9);
      border-color: $error;
      background: rgba($error, 0.1);

      a {
        color: $error;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }

        &:after {
          background: $error;
          transform: scale(1, 1) translateY(-1px);
          transform-origin: left center;
        }
      }
    }
  }

  &__alert-box {
    &.error {
      color: rgba($error, 0.9);
      border-color: $error;
      background: rgba($error, 0.1);
    }
  }

  &__title-name {
    flex: 0 0 $gutter--12 * 6;
  }

  &__phone-number {
    flex: 0 0 175px;

    input[type="text"] {
      padding: 16px 12px;
    }
  }

  &__ccv {
    flex: 0 0 $gutter--12 * 5;
    max-width: 54px;

    > input {
      text-align: center;
    }
  }

  &__date {
    flex: 0 0 auto;

    input {
      width: 120px;
      padding-right: $base--8 * 4;
    }
  }

  &__bsb-number {
    max-width: 86px;
    flex: 0 0 86px;
  }

  &__account-number {
    max-width: 108px;
    flex: 0 0 108px;
  }

  &__account-number,
  &__bsb-number,
  &__ccv {
    div & {
      &:not(:last-of-type) {
        margin-right: $gutter--12 * 2;

        @include respond-to("small-down", max) {
          margin-right: $gutter--12 * 2;
        }
      }
    }
  }

  &__amount {
    &.small-auto {
      flex: 0 0 90px;

      input {
        padding-left: $base--8 * 2.5;
      }
    }

    &--dollar {
      position: absolute;
      top: 14px;
      left: $gutter--12;
      // transform: translateY(calc(-50% + 0));
      color: $blue--mid;
      font-size: 12px;
      z-index: 1;
    }
  }

  &__email-address {
    position: relative;

    &.change-password-link {
      a {
        position: absolute;
        bottom: -18px;
        left: 0;
      }
    }
  }
}

.custom_password_strength {
  .password_strength {
    margin: 0;
  }
}

.password-strength-display {
  * {
    padding-top: 4px;
  }
}

//	-------------------------------------------------------------
//
//	BUTTONS
//
//	------------------------------------------------------------

// button pecific variables
$button_height: 40px;

// button loader mixin
@mixin button_loader($spinner_color,$loader_color) {
	cursor: default;
	&:after {
		content: "";
		$loader_size: rem-calc(28);
		$border_size: rem-calc(4);
		position: absolute;
		top: 50%;
		left: 50%;
		height: $loader_size;
		width: $loader_size;
		margin-top: -($loader_size/2);
		margin-left: -($loader_size/2);
		border-radius: 100%;
		border: $border_size solid $loader_color;
		border-left-color: $spinner_color;
		@include animation(animate_button_loader 1.1s infinite linear);
	}
	&.btn_pri {
		color: $alpha;
        background: $alpha;
        &:after{
            background:none;
        }
	}
	&.btn_sec {
        color: white;
        &:after{
            background:none;
        }
	}
}

// button forward & back mixin - general styles for both forward and back buttons
@mixin button_forward_back {
	width: rem-calc(148);
	// padding: 0 rem-calc(60);
	&:after {
		$size: rem-calc(22);
		font-family: $icon;
		font-size: $size;
		font-weight: normal;
		position: absolute;
		top: 50%;
		height: $size;
		width: $size;
		opacity: 0;
		@include transform(translate(0,-50%));
		transition: all $transition;
	}
	&:hover {
		&:after {
			opacity: 1;
		}
	}

}

// button back mixin
@mixin button_back($color) {
	@include button_forward_back;
	color: $color;
	&:after {
		content: "arrow_back";
		left: 0;
		@include transform(translate(50px,-50%));
	}
	&:hover {
		&:after {
			@include transform(translate(20px,-50%));
		}
	}
}

// reset button styles
button {
	cursor: pointer;
	border: none;
	outline: none;
	background: transparent;
}

.btn {
	&__wrapper {
		margin-top: rem-calc(8);
	}

	&__content {
		font-size: 12px;
		margin-left: rem-calc(12);
	}
}

// base buttons styles
.btn_pri,
.btn_blue,
.btn_sec,
.btn_ter {
	position: relative;
	font-size: rem-calc(13);
	text-align: center;
	height: $button_height;
	padding: 0 rem-calc(40);
	border-radius: $button_height;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.10);
	// disabled
	&--disabled {
		cursor: default;
		opacity: 0.5;
	}
	// stretch
	&--stretch {
		width: 100%;
	}
	&--skinny {
		padding: 0 rem-calc(25);
	}
}

// text align a tag buttons like <button> element
a {
	&.btn_pri,
	&.btn_blue,
	&.btn_sec,
	&.btn_ter {
		@include inline-flex;
		@include align-items(center);
        @include justify-content(center);

        &:after{
            background:none;
        }
	}
}

.btn_blue {
	color: white;
	font-weight: $font_bold;
	background: $alpha;

	&--invoice {
		width: 200px;
	}
	&--loading {
		// ..
	}
	&--forward {
		// ..
	}
	&--back {
		// ..
	}
	&:not(.btn_blue--disabled,.btn_blue--loading) {
		&:hover {
			// ..
		}
	}
}

// button primary styles
.btn_pri {
	color: white;
	font-weight: $font_bold;
	background: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);
	transition-duration: 0.3s;
	&--loading {
		// ..
	}
	&--forward {
		// ..
	}
	&--back {
		// ..
	}
	&:hover {
		box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1), 0 30px 50px 0 rgba(11, 34, 50, 0.1);
	}
}

// button secondary styles
.btn_sec {
	color: $gamma;
	background: white;
	border: 1px solid white;
	transition: color $transition, border $transition, background $transition;
	&--loading {
		// ..
	}
	&--forward {
		// ..
	}
	&--back {
		// ..
	}
	&:hover {
		color: $alpha;
		border-color: $alpha;
	}
	.btn_sec--disabled,.btn_sec--loading {
		pointer-events: none;
	}
}

// button tertiary styles
.btn_ter {
	font-weight: $font_bold;
	color: $alpha;
	box-shadow: none;
	transition: color $transition, background $transition;
	&--loading {
		// ..
	}
	&--forward {
		// ..
	}
	&--back {
		@include button_back($alpha);
	}
	&:not(.btn_pri--disabled,.btn_pri--loading) {
		&:hover {
			background: $psi;
		}
	}
	// breakpoints / media queries
	@include bp(small) {
		background: $psi;
	}
}

// inline buttons
// wrap in a .inline_buttons div
.inline_buttons {
	width: 100%;
	@include clearfix;
	.btn_pri,
	.btn_sec,
	.btn_ter {
		float: left;
		margin-right: rem-calc(15);
		&--last {
			margin-right: 0;
		}
	}
	&--right_aligned {
		.btn_pri,
		.btn_sec,
		.btn_ter {
			float: right;
		}
	}
	// breakpoints / media queries
	@include bp(small) {
		.btn_pri,
		.btn_sec,
		.btn_ter {
			margin-right: 0;
			margin-bottom: rem-calc(15);
			&--last {
				margin-bottom: 0;
			}
		}
		&--right_aligned {
			.btn_pri,
			.btn_sec,
			.btn_ter {
				margin-bottom: 0;
				&--last {
					margin-bottom: rem-calc(15);
				}
			}
		}
	}
}

// close icon button
.btn_close {
	$size: rem-calc(30);
	position: relative;
	width: $size;
	height: $size;
	text-align: center;
	transition: background $transition;
	background: transparent;
	border-radius: 100%;
	&:hover {
		background: $delta;
	}
	&:after {
		font-family: $icon;
		font-size: rem-calc(26);
		color: $alpha;
		content: "close";
		line-height: $size;
		@include stretch;
	}
}

// animation for button loader
@include keyframes(animate_button_loader) {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}

// here for reference
// <a href="" class="btn_pri"></a>
// <a href="" class="btn_sec"></a>
// <a href="" class="btn_pri btn_pri--disabled"></a>
// <a href="" class="btn_sec btn_sec--disabled"></a>
// <a href="" class="btn_pri btn_pri--forward"></a>
// <a href="" class="btn_sec btn_sec--forward"></a>
// <a href="" class="btn_pri btn_pri--loading"></a>
// <a href="" class="btn_sec btn_sec--loading"></a>
// <a href="" class="btn_pri btn_pri--stretch"></a>
// <a href="" class="btn_sec btn_sec--stretch"></a>

// some specific styles
.confirm_donation_buttons {
	margin-bottom: rem-calc(18);
	.form_checkbox_basic {
		display: inline-block;
		margin-left: rem-calc(20);
		// breakpoints / media queries
		@include bp(small) {
			margin-left: 0;
			margin-top: rem-calc(20);
			padding: 0 rem-calc(10);
		}
	}
}

.js__donate {
	$icon_size: rem-calc(22);
	$padding_left: rem-calc(48);
	// icon
	&:before {
		display: none;
		position: absolute;
		content: "done";
		top: 50%;
		left: ( $padding-left - $icon_size ) / 2;
		width: $icon_size;
		height: $icon_size;
		font-family: $icon;
		font-size: $icon_size;
		line-height: $icon_size;
		@include transform(translateY(-50%));
	}

	&.js__confirm_donation {
		padding-left: $padding_left;
		// icon
		&:before {
			display: block;
		}
	}
}

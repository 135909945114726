@import "../abstracts/all";
@import "../base/typography";
@import "../layout/flex";
@import "../layout/forms";
@import "../components/modals/change-password";

@include component(
  (
    "1-column": 0,
    "2-column": 1024px,
  )
) {
  .settings {
    display: flex;
    flex-wrap: wrap;

    &__block {
      flex: 1;
      align-self: flex-start;
      background: #fff;
      box-shadow: 0 30px 50px 0 rgba(11, 34, 50, 0.1),
        0 6px 12px 0 rgba(11, 34, 50, 0.1);
      border-radius: 3px;
      padding: $border-spacing;
    }

    @include respond-to("1-column") {
      &__block {
        max-width: 600px;
        margin: 0 auto;
        flex-basis: 100%;
        margin-bottom: $gutter--12 * 3;
      }
    }

    @include respond-to("2-column") {
      flex-wrap: nowrap;

      &__block {
        flex-basis: 100%;

        &.settings--personal {
          margin-right: $gutter-spacing;
        }
      }
    }
  }
}

/**
 * Program Cards
 * @type {[Card]}
 */

@import "../abstracts/all";

@include component(
  (
    "2-column": 590px,
    "3-column": 880px,
    "4-column": 1080px,
  )
) {
  .program-grid {
    max-width: 100%;
    display: block;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    @include respond-to("2-column") {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr 1fr;
    }

    @include respond-to("3-column") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-columns: 1fr 1fr 1fr;
    }

    @include respond-to("4-column") {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .program {
    // padding: $gutter--12;
    // flex-basis: 100%;
    max-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 408px;
    margin-bottom: 10%;
    display: block;

    @include respond-to("2-column") {
      // flex-basis: 50%;
      // max-width: 50%;
      margin: 0;
      order: 2;
      max-width: 100%;

      &--new {
        order: 1;
      }
    }

    @include respond-to("3-column") {
      // flex-basis: 33%;
      // max-width: 33%;
      &--new {
        order: 2;
      }
    }

    @include respond-to("4-column") {
      // flex-basis: 25%;
      // max-width: 25%;
      max-width: 100%;
    }

    &:nth-child(1n + 4) {
      // padding:0;
    }

    &--new {
      flex: 1 1;

      &:after {
        background: none;
      }

      .program__wrap {
        padding: $base--8 * 2;
      }


      .program__add-new-btn {
        color: $blue--mid;
      }

      .program__new-inset {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
          -180deg,
          rgba(50, 145, 255, 0) 0%,
          rgba(144, 196, 255, 0.21) 99%
        );
        box-shadow: inset 0 0 20px 10px rgba(23, 91, 141, 0.03);
        border-radius: 3px;
        height: 100%;
        min-height: 352px;

        .dashed-line--bottom,
        .dashed-line--top {
          width: 100%;
          height: 1px;
          left: 0;
          position: absolute;
        }

        .dashed-line--top {
          top: 0;
          background: linear-gradient(to right, transparent 50%, white 50%),
            linear-gradient(
              to right,
              rgba($blue--mid, 0.1),
              rgba($blue--mid, 0.2)
            );
          background-size: 16px 2px, 100% 2px;
        }

        .dashed-line--bottom {
          bottom: 0;
          background: linear-gradient(to right, transparent 50%, white 50%),
            linear-gradient(to right, $blue--mid, rgba($blue--mid, 1));
          background-size: 16px 2px, 100% 2px;
        }

        .dashed-line--left,
        .dashed-line--right {
          height: 100%;
          width: 1px;
          top: 0;
          position: absolute;
        }

        .dashed-line--left {
          left: 0;
          background: linear-gradient(to top, transparent 50%, white 50%),
            linear-gradient(to top, $blue--mid, rgba($blue--mid, 0.2));
          background-size: 2px 16px, 2px 100%;
        }

        .dashed-line--right {
          right: 0;
          background: linear-gradient(to top, transparent 50%, white 50%),
            linear-gradient(to top, $blue--mid, rgba($blue--mid, 0.2));
          background-size: 2px 16px, 2px 100%;
        }
      }
    }


    .program__edit-icon {
      color: $blue--mid;
      position: absolute;
      right: 12px;
      top: 12px;
      opacity: 0.85;
    }

    &__wrap {
      position: relative;
      background: #fff;
      box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1),
        0 30px 50px 0 rgba(11, 34, 50, 0.1);
      border-radius: 3px;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &.donation_portal__aside {
        padding: $gutter--24;
      }

      &:hover {
        .program__edit-icon {
          opacity: 1;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      max-width: 260px;
      margin: 0 auto;
      flex: 1;

      p {
        font-weight: 400;
        color: $body-font-color;
        text-align: center;
      }

      small {
        text-transform: uppercase;
        color: $body-font-color;
        font-weight: bold;
        margin-bottom: $base--8;
      }
    }

    &__location {
      flex: 1;
    }

    &__profile-pic {
      border-radius: 100%;
      width: 120px;
      height: 120px;
      box-shadow: 0 10px 30px 0 rgba(20, 49, 90, 0.15),
        0 4px 8px 0 rgba(20, 49, 90, 0.04);
      margin-bottom: $base--8 * 4;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 48px;
      }
    }

    &__recipient {
      color: $body-font-color;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: $gutter--24;
      text-align: center;
      flex: 1;
      height: 42px;
    }

    &__next-donation-date {
      color: $blue--mid;
      vertical-align: middle;
    }

    &__footer {
      display: flex;
      align-items: center;
      border-top: 1px solid $border--light;

      p {
        margin: 0;
        padding: $base--8;
      }

      &--amount {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--type {
        flex: 0 0 auto;
        padding: $gutter--12 $base--8 * 3;
        box-sizing: content-box;
        border-left: 1px solid $border--light;
        color: $blue--mid;
      }

      &--dollar {
        color: $blue--mid;
        font-size: 14px;
        font-weight: 400;
        padding-right: 2px;
      }

      &--value {
        color: $blue--mid;
        font-size: 21px;
        padding-right: $base--8;
      }

      &--frequency {
        position: relative;
        text-transform: lowercase;
        padding-left: $base--8;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) translateX(-1px) rotate(12deg);
          width: 1px;
          height: 24px;
          background: $border--light;
        }
      }
    }
  }
}

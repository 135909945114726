//	-------------------------------------------------------------
//
//	FORMS
//
//	-------------------------------------------------------------

// Contents:
// 0. Extends
// 1. Layout
// 2. Labels
// 3. Inputs
// 4. Notes
// 5. Error Messages
// 6. Checkboxes
// 7. Radio Buttons
// 8. Search
// 9. Select Menu
// 10. Other
// 11. Twitter Typeahead
// 12. Google Address Search Results
// 13. Notify Box
// 14. Password Meter
// NOTE: for dropdown/select menus refer to 'components/chosen'
// NOTE: for buttons refer to 'components/buttons'

//	-------------------------------------------------------------

// form specific variables
$default_element_height: rem-calc(44);

//	-------------------------------------------------------------

// 0. extends for global styles

// used for: .form_radio_label, .form_feature_checkbox_label
%form_button_label {
  @include flex-box;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  height: rem-calc(38); // equal to the height of buttons
  font-size: rem-calc(13);
  color: $gamma;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid $psi;
  transition: color $transition, background $transition;
  border-radius: rem-calc(38);
  &:hover {
    color: $alpha;
  }
}

// used for: .form_radio_input, .form_feature_checkbox_input
%form_button_checkbox {
  @include visuallyhidden;
  &:checked {
    + label {
      cursor: default;
      color: $alpha;
      background: $psi;
    }
  }
}

//	-------------------------------------------------------------

// 1. layout
.form {
  position: relative;
  width: 100%;
  height: auto;
  // breakpoints / media queries
  @include bp(small) {
    // add gradient border top
    // target .form's inside .donation_portal so it doesn't effect modal forms etc..
    .donation_portal & {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: rem-calc(2);
        background-image: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);
      }
    }
  }

  &__alert-box {
    display: inline-flex;
    background: $psi;
    border-radius: 4px;
    padding: rem-calc(20) rem-calc(30);
  }
}

.form_section {
  width: 100%;
  padding: rem-calc(50);
  border-bottom: 1px solid $kappa;
  &--last {
    border-bottom: 0;
  }
  // breakpoints / media queries
  @include bp(small) {
    padding: rem-calc(30) rem-calc(20);
  }
}

.form_foot {
  width: 100%;
  padding: rem-calc(40) rem-calc(50);
  // breakpoints / media queries
  @include bp(small) {
    padding: rem-calc(30) rem-calc(20);
  }
}

.form_row {
  width: 100%;
  @include clearfix;
  margin-bottom: rem-calc(15);
  h2 {
    margin-bottom: rem-calc(5);
  }
  h3 {
    margin-bottom: rem-calc(5);
  }
  &--last {
    margin-bottom: 0;
  }
}

.form_column {
  position: relative;
  height: auto;

  // form column sizes are based of the parent container which is 9 columns wide
  // we have 4 form column sizes:
  // .form_column--1-2 - one half
  // .form_column--1-3 - one third
  // .form_column--2-3 - two thirds
  // .form_column by default spans the full width

  &--1-2 {
    @include span(4.5 of 9);
  }
  &--1-3 {
    @include span(3 of 9);
  }
  &--2-3 {
    @include span(6 of 9);
  }

  // kill margin-right on all last .form_column's
  &:last-child {
    &:not(.form_column--centered) {
      margin-right: 0;
    }
  }

  // centered form columns
  // 2/3 centered
  &--centered {
    width: 100%;
    max-width: rem-calc(385); // roughly equal to the size of .form_column--2-3
    margin: 0 auto;
  }

  // breakpoints / media queries
  @include bp(small) {
    &--1-2,
    &--1-3,
    &--2-3 {
      width: 100%;
      margin-bottom: rem-calc(15);
      &:last-child,
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &--centered {
      max-width: 100%;
      margin-bottom: rem-calc(15);
      &:last-child,
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

//	-------------------------------------------------------------

// 2. base form label styles
.form_label {
  display: block;
  font-size: rem-calc(13);
  color: $gamma;
  width: 100%;
  margin-bottom: rem-calc(5);
}

//	-------------------------------------------------------------

// 3. base form input styles
.form_input,
%form_input {
  display: block;
  width: 100%;
  height: $default_element_height;
  outline: none;
  font-size: rem-calc(15);
  padding: rem-calc(1) rem-calc(10) 0;
  border: 1px solid $beta;
  border-radius: rem-calc(3);
  transition: border $transition, box-shadow $transition;
  box-shadow: none;
  -webkit-appearance: none;
  // focus state
  &:focus {
    border: 1px solid $alpha;
    box-shadow: $global_box_shadow;
  }
  // error state
  &.error {
    border-color: $warning;
    background: rgba($warning, 0.15);
    &:focus {
      border-color: $warning;
    }
  }
  &[disabled] {
    opacity: 0.5;
  }
  // placeholder styles
  @include placeholder {
    font-weight: 100;
    color: $gamma;
  }
}

// .form_input_wrap is used when you need to have a position relative wrapper
// e.g.form_input_icon below
.form_input_wrap {
  width: 100%;
  position: relative;
  z-index: 1;
  .form_input {
    position: relative;
    z-index: 2;
  }
}

// form icon style, used to symbol the input type, e.g. "$"
// place before the .form_input for correct styling
.form_input_icon {
  position: absolute;
  top: rem-calc(1);
  left: rem-calc(1);
  background: $psi;
  z-index: 3;
  + .form_input {
    padding-left: $default_element_height + rem-calc(10);
  }
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $alpha;
    font-family: $icon;
    font-size: rem-calc(18);
    @include transform(translate(-50%, -50%));
  }
  &--dollar {
    &:after {
      content: "attach_money";
    }
  }

  &--close {
    position: absolute;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background: $psi;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &--help-circle {
    &:after {
      content: "help-circle";
    }
  }
  // declare other icon styles here using the BEM modifier syntax
  // &--example {
  // 	&:after {
  // 		content: "icon_name";
  // 	}
  // }
}

//	-------------------------------------------------------------

// 4. form notes - to be placed before the closing .form_column tag
.form_note,
%form_note {
  width: 100%;
  color: $omega;
  font-size: rem-calc(13);
  line-height: 1.2;
  margin-top: rem-calc(10);
  padding: 0 rem-calc(11);
  .text_link {
    color: $alpha;
    font-weight: $font_regular;
  }
}

.security_notice {
  display: flex;
  padding: 0;
  i {
    color: #c9cdcf;
    padding-top: 3px;
    padding-right: 8px;
  }
}
//	-------------------------------------------------------------

// 5. form error message - to be placed before the closing .form_column tag
.form_row {
  small {
    &.error {
      @extend %form_note;
      display: block;
      color: $warning;
    }
  }
}

//	-------------------------------------------------------------

// 6. form check box

// 6.1 conventional styled checkbox
.form_checkbox_input {
  @include visuallyhidden;
  &:checked {
    + .form_checkbox_label {
      &:after {
        @include transform(scale(1));
      }
    }
  }
}

.form_checkbox_label {
  $checkbox_outer: rem-calc(28);
  $checkbox_inner: rem-calc(18);
  display: inline-block;
  position: relative;
  color: $gamma;
  padding: rem-calc(7) 0;
  padding-left: $checkbox_outer + rem-calc(10);
  font-size: rem-calc(15);
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 100%;
  }
  &:before {
    $size: $checkbox_outer;
    top: 50%;
    left: 0;
    width: $size;
    height: $size;
    background: white;
    border: 1px solid $beta;
    margin-top: -$size / 2;
  }
  &:after {
    $size: $checkbox_inner;
    width: $size;
    height: $size;
    background: $alpha;
    top: 50%;
    left: ($checkbox_outer - $checkbox_inner) / 2;
    margin-top: -$size / 2;
    @include transform(scale(0));
    transition: transform $transition;
  }
}

// 6.1 html reference
// <div class="form_column">
// 	<input class="form_checkbox_input" type="checkbox" value="" id="" name="">
// 	<label class="form_checkbox_label general_fund_label" for="">[checkbox-name]</label>
// </div>

// 6.2 featured checkbox — has the same styles as AFES radio buttons
.form_feature_checkbox_label {
  @extend %form_button_label;
}

.form_feature_checkbox_input {
  @extend %form_button_checkbox;
  &:checked {
    + label {
      cursor: pointer;
    }
  }
}

// 6.2 html reference
// <div class="form_column">
// 	<input class="form_feature_checkbox_input" type="checkbox" value="" id="" name="">
// 	<label class="form_feature_checkbox_label general_fund_label" for="">[checkbox-name]</label>
// </div>

// 6.3 basic checkbox / default browser checkbox with slightly refined styles
.form_checkbox_basic {
  font-size: rem-calc(15);
  display: flex;

  label {
    display: flex;
    align-items: flex-start;
  }
  input,
  span {
    display: inline-block;
    line-height: 20px;
  }
  input {
    margin-top: 4px;
  }
  span {
    flex: 1;
    padding-left: rem-calc(8);
  }
}

// 6.3 html reference
// <label for="" class="form_checkbox_basic">
// 	<input type="checkbox" id="" name="" value=""><span>[checkbox-name]</span>
// </label>

//	-------------------------------------------------------------

// 7. form radio buttons
.form_radio_label {
  @extend %form_button_label;
}

.form_radio_input {
  @extend %form_button_checkbox;
}

//	-------------------------------------------------------------

// 8. from search field
.form_search {
  $button_size: rem-calc(26);
  position: relative;
  width: 100%;
  &__input {
    position: relative;
    z-index: 1;
    @extend %form_input;
    padding-right: $button_size + rem-calc(10);
    box-sizing: border-box;
  }
  &__button,
  &__icon {
    position: absolute;
    top: 50%;
    right: rem-calc(10);
    z-index: 1000; // needs to sit above typeahead container which is z-index 999
    color: $omega;
    width: $button_size;
    height: $button_size;
    transition: color $transition;
    @include transform(translateY(-50%));
    &:after {
      font-family: $icon;
      content: "search";
      @include stretch;
      font-size: $button_size;
    }
    &--clear {
      cursor: pointer;
      &:after {
        content: "clear";
      }
      &:hover {
        &:after {
          color: $alpha;
        }
      }
    }
    @include bp(small) {
      right: rem-calc(5);
    }
  }
}

//	-------------------------------------------------------------

// 9. form select menu
select {
  // reset browser styles
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // outline: 0;
  // custom styles
  @extend %form_input;
  height: rem-calc(38); // equal to the height of buttons
  font-size: rem-calc(13);
  color: $gamma;
  border: 1px solid #acb5bc;
  border-radius: 3px;
  background: url("../../../assets/images/select_menu_arrow.svg") center right
    10px/16px 15px no-repeat;
  &.is_large {
    // matches the same height as a standard input field
    height: $default_element_height;
  }
}

@-moz-document url-prefix() {
  select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
  }
}

select::-ms-expand {
  display: none;
}

//	-------------------------------------------------------------

// 10. other form elemens

// form divider
// used to help split up from row if needed
// can be used with or with out text wrapped in a span
.form_divider {
  position: relative;
  width: 100%;
  min-height: rem-calc(2);
  margin: rem-calc(35) 0;
  text-align: center;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: rem-calc(320);
    height: rem-calc(1);
    background: $kappa;
    @include transform(translateX(-50%));
    z-index: 1;
  }
  span {
    display: inline-block;
    position: relative;
    font-size: rem-calc(15);
    line-height: rem-calc(14);
    font-style: italic;
    color: $beta;
    font-weight: $font_bold;
    padding: 0 rem-calc(20);
    background: white;
    z-index: 2;
    vertical-align: rem-calc(2);
  }
}

//	-------------------------------------------------------------

// 11. twitter typeahead
.twitter-typeahead {
  width: 100%;
  display: block !important;
  z-index: 999;
  .tt-menu {
    width: 100%;
    height: auto;
    padding-top: rem-calc(5);
    .tt-dataset {
      width: 100%;
      font-size: rem-calc(15);
      @include card;
    }
    .tt-selectable,
    .tt-suggestion {
      color: $gamma;
      background: transparent;
      transition: color $transition, background $transition;
      padding: rem-calc(8) rem-calc(10);
      cursor: pointer;
      &:hover,
      &.tt-cursor {
        color: $alpha;
      }
      &.tt-cursor {
        background: $psi;
      }
      &:first-child {
        padding-top: rem-calc(16);
      }
      &:last-child {
        padding-bottom: rem-calc(16);
      }
      @include bp(small) {
        padding: rem-calc(12) rem-calc(10);
      }
    }
    strong {
      font-weight: $font_regular;
    }
  }
}

// 11. html reference
// <div class="tt-menu" style="position: absolute; top: 100%; left: 0px; z-index: 100; display: none;">
// <div class="tt-dataset tt-dataset-charities">
// <div class="tt-suggestion tt-selectable">
// <strong><strong class="tt-highlight">Sydn</strong>ey - Macquarie Uni</strong>
// </div>
// </div>
// </div>

//	-------------------------------------------------------------

// 12. google address search results
// NOTE: styles replicate twitter typeahead styles above, try to keep them consistent
.pac-container {
  // reset some default styles
  border-top: none;
  font-family: $base;
  box-shadow: none;
  // custom styles
  @include card;
  padding: rem-calc(8) 0;
  margin-top: rem-calc(5);
  // kill "powered by Google" logo - Christian said it was ok :)
  &:after {
    display: none;
  }
  .pac-item {
    // reset some default styles
    line-height: 1;
    border-top: none;
    // custom styles
    position: relative;
    color: $gamma;
    font-size: rem-calc(15);
    background: transparent;
    transition: color $transition, background $transition;
    padding: rem-calc(8) rem-calc(10);
    padding-left: rem-calc(40);
    cursor: pointer;
    &:empty {
      padding: 0;
    }
    &:hover,
    &.pac-item-selected {
      color: $alpha;
      .pac-icon {
        color: $alpha;
      }
      .pac-item-query {
        color: $alpha;
      }
    }
    &.pac-item-selected {
      background: $psi;
    }
  }
  .pac-icon {
    // reset some default styles
    margin-right: 0;
    margin-top: 0;
    display: block;
    background-image: none;
    // custom styles
    $size: rem-calc(20);
    width: $size;
    height: $size;
    position: absolute;
    top: 50%;
    left: rem-calc(10);
    color: $beta;
    text-align: center;
    transition: color $transition;
    margin-top: -$size / 2;
    &:after {
      display: block;
      position: relative;
      top: 50%;
      font-family: $icon;
      font-size: rem-calc(18);
      @include transform(translateY(-50%));
    }
  }
  .pac-icon-marker {
    &:after {
      content: "room";
    }
  }
  .pac-item-query {
    // custom styles
    font-size: rem-calc(15);
    padding-right: rem-calc(3);
    color: $gamma;
  }
  .pac-matched {
    // custom styles
    font-weight: $font_bold;
  }
}

// 12. html reference
// <div class="pac-container pac-logo" style="width: 618px;position: absolute;left: 140px;top: 588px;/* display: none; */">
// 	<div class="pac-item">
// 		<span class="pac-icon pac-icon-marker"></span>
// 		<span class="pac-item-query">
// 			<span class="pac-matched">Arizona</span>
// 		</span>
// 	</div>
// </div>

//	-------------------------------------------------------------

// 13. form notify box
.form_notify_box {
  display: block;
  width: 100%;
  font-size: rem-calc(13);
  text-align: center;
  line-height: 1.2;
  padding: rem-calc(5);
  margin-top: rem-calc(10);
  @include notify;
  &--large {
    padding: rem-calc(10);
  }
}

// 14. Password Meter

.password-meter {
  .password-meter-message {
    @extend .form_notify_box;
    &:empty {
      display: none;
    }
  }
  .password-meter-bg {
    display: none;
  }
}

// 14. html reference
// <div class="password-meter">
// 	<div class="password-meter-message password-meter-message-too-short">Too short - Password should be 8 or more characters</div>
// 	<div class="password-meter-bg">
// 		<div class="password-meter-bar password-meter-too-short"></div>
// 	</div>
// </div>

// datepicker
.Zebra_DatePicker_Icon.Zebra_DatePicker_Icon_Inside_Right {
  $button_size: rem-calc(24);
  font-size: 0;
  color: rgba(0, 0, 0, 0);
  text-indent: 0;
  background: transparent;
  width: $button_size;
  height: $button_size;
  right: 5px !important;
  &:after {
    font-family: $icon;
    font-size: $button_size;
    line-height: $button_size;
    color: $alpha;
    content: "date_range";
  }
}

.Zebra_DatePicker_Icon_Wrapper {
  width: 100% !important;
}

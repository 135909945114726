//	-------------------------------------------------------------
//
//	SIDEBAR VIEW
//
//	-------------------------------------------------------------

$aside_vertical_gutters: rem-calc(32);

.aside_logo {
	height: ($navigation_height) - rem-calc(1); // inline with the bottom of the navigation graident
	&__svg {
		@include flex-box;
		@include align-items(center);
		width: 100%;
		height: 100%;
		svg {
			display: block;
			width: rem-calc(95);
			height: auto;
		}
	}
}

.aside_head {
	padding: $aside_vertical_gutters 0;
	border-top: 1px solid $beta;
	&__user {
		font-weight: $font_bold;
	}
	&__message {
		width: 100%;
		max-width: rem-calc(330);
		font-size: rem-calc(18);
		line-height: 1.3;
		margin: 0;
	}
	.text_link {
		display: inline-block;
		color: $alpha;
		font-size: rem-calc(12);
		text-transform: uppercase;
		font-weight: $font_bold;
		margin: rem-calc(18) 0 0 0;
	}
}

.aside_form {
	padding-bottom: $aside_vertical_gutters;
}

.aside_details {
	$offset: rem-calc(145);
	@include listreset;
	padding: $aside_vertical_gutters 0;
	border-top: 1px solid $beta;
	border-bottom: 1px solid $beta;
	&__item {
		position: relative;
		display: block;
		line-height: 1.3;
		font-size: rem-calc(15);
		margin-bottom: rem-calc(18);
		min-height: rem-calc(20);
		&:last-child {
			margin-bottom: 0;
		}
		&--heading {
			margin-bottom: rem-calc(10);
		}
	}
	&__label {
		position: absolute;
		top: 0;
		left: 0;
		color: $alpha;
		width: $offset;
	}
	&__meta {
		display: block;
		width: auto;
		padding-left: $offset;
	}
	.auth-reset &,
	.auth-login &,
	.auth-forgot &,
	.auth-activate &,
	.not_found & {
		display: none;
	}
	h2 {
		font-size: rem-calc(12);
		text-transform: uppercase;
		margin-bottom: 0;
		font-weight: $font_bold;
	}
}

.aside_foot {
	padding: $aside_vertical_gutters 0;
	.auth-reset &,
	.auth-login &,
	.auth-forgot &,
	.auth-activate &,
	.not_found & {
		display: none;
	}

	&--help {
		display: flex;
		> * {
			font-size: 12px!important;
		    flex: 1 1 auto;
		    padding-left: 0;
		    padding-right: 0;
		}
	}

}

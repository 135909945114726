@import "../abstracts/colours";
@import "../abstracts/variables";
@import "../abstracts/breakpoints";

.auth-login,
.auth-forgot {
  .donation_portal {
    &__main {
      width: 100%;
      max-width: 600px;
      float: none;
      margin: auto;
    }
  }
}

.donation_portal {
  &__main {
    width: 100%;
    float: none;
  }

  .text_link {
    color: #4a90e2;
    width: fit-content;
    cursor: pointer;
  }

  h1 {
    font-size: 24px;
    margin-bottom: $base--8 * 3;

    @include respond-to("medium-down") {
      font-size: 32px;
      margin-bottom: $base--8 * 4;
    }
  }

  &__container {
    margin-top: -160px;

    @include respond-to("medium-down") {
      margin-top: -142px;
    }
  }
}

.panel {
  z-index: 0;

  &__content {
    padding: $base--8 * 4 $gutter--12;

    @include respond-to("medium-down") {
      padding: $base--8 * 2 $gutter--12 * 2;
    }
  }

  &__foot {
    display: flex;
    justify-content: flex-end;
    padding: $base--8 * 3 $gutter--12 * 11;
    border-top: 1px solid $border--light;

    button {
      width: 140px;
    }
  }

  &__footnote {
    padding: $base--8 * 2;
    margin-top: $base--8 * 2;
    border: 1px solid $border--mid;
    border-radius: 3px;

    i {
      font-size: 14px;
      margin-right: $base--8;
    }

    p {
      font-size: 13px;
      display: flex;
      color: $gamma;
    }
  }

  &__login {
    max-width: 600px;
    margin: auto;

    .form_section {
      padding-bottom: 0;
    }
  }
}

.donation_panel {
  padding-left: $gutter--12 * 2;
  padding-right: $gutter--12 * 2;

  @include respond-to("medium-down") {
    padding-left: $gutter--12 * 5;
    padding-right: $gutter--12 * 5;
  }

  @include respond-to("large-down") {
    padding-left: $gutter--12 * 10;
    padding-right: $gutter--12 * 10;
  }

  max-width: 1000px;
  margin: auto;

  &__row {
    @include respond-to("medium-down") {
      display: flex;
      align-items: flex-start;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: $base--8 * 2;
    margin-bottom: $base--8 * 3;
    border-bottom: 1px solid $border--light;

    @include respond-to("medium-down") {
      display: none;
    }

    .tabbed_nav__number {
      color: $blue--mid;
      border: 1px solid $blue--mid;
      font-weight: 500;
    }

    .tabbed_nav__title {
      color: $blue--mid;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__tooltip {
    span {
      font-weight: 600;
    }
  }

  &__field {
    margin-bottom: $base--8 * 2;
  }

  &__field [class^="col"] {
    @include respond-to("medium-down") {
      padding-right: $gutter--12;
    }
  }

  &__title {
    color: $blue--mid;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: $base--8 * 3;
    line-height: 20px;

    span {
      font-weight: 600;
    }
  }

  &__datepicker {
    width: 100%;

    .react-datepicker__input-container,
    .react-datepicker-wrapper {
      width: 100%;
    }
    input {
      cursor: pointer;
    }
  }

  &__autocomplete {
    position: relative;
  }

  &__frequency {
    text-transform: lowercase;
  }

  &__group {
    margin-bottom: $base--8 * 2;

    @include respond-to("medium-down") {
      margin-bottom: $base--8 * 4;
    }

    select {
      border-radius: 3px;
    }
  }

  &__group [class^="col"] {
    margin-bottom: $gutter--12;

    @include respond-to("medium-down") {
      margin-bottom: 0;
      padding-right: $gutter--12;
    }
  }

  &__note {
    margin-top: $base--8;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 0;
    font-size: 14px;

    @include respond-to("medium-down") {
      margin-top: 0;
      margin-left: $base--8 * 3;
      line-height: normal;
    }
  }

  &__heading {
    font-size: 18px;
    text-align: left;
    line-height: 28px;
    margin-bottom: 0;

    br {
      display: none;

      @include respond-to("medium-down") {
        display: block;
      }
    }

    @include respond-to("medium-down") {
      text-align: center;
      font-size: 22px;
      line-height: 32px;
    }

    span {
      font-weight: 600;
    }
  }

  &__span {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    color: $body-font-color;
  }

  .form {
    &_note {
      margin-top: $gutter--12;
    }

    &__block {
      border-bottom: 1px solid $border--light;
      padding-bottom: $base--8 * 3;
      padding-top: $base--8 * 3;
      margin-bottom: 0;

      @include respond-to("medium-down") {
        padding-bottom: $base--8 * 5;
        padding-top: $base--8 * 5;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__row {
      display: flex;
      align-items: center;
    }
  }

  label {
    display: flex;
    align-items: center;
  }

  .react-autosuggest__container {
    @include respond-to("medium-down") {
      padding-right: $gutter--12 * 2;
    }
  }

  .search_info_icon {
    right: -8px;
  }
}

.donation_panel,
.payment_summary {
  .buttons {
    margin-top: $base--8 * 4;
    padding-top: $base--8 * 2;
    border-top: 1px solid $border--light;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .btn__solid--white {
      order: 2;
    }

    @include respond-to("medium-down") {
      flex-direction: row;
      padding-top: $base--8 * 3;

      .btn__solid--white {
        order: 1;
      }

      .btn__solid {
        order: 2;
      }
    }
  }
}

//	-------------------------------------------------------------
//
//	TABS NAVIGATION
//
//	-------------------------------------------------------------

.menu_tabs {
	width: 100%;
	border-bottom: 2px solid $kappa;
	@include clearfix;
	&__item {
		float: left;
		width: 100%;
		height: rem-calc(55);
		border-right: 1px solid $kappa;
		&:last-child {
			border-right: none;
		}
		&--1-2 {
			width: percentage(1/2);
		}
		&--1-3 {
			width: percentage(1/3);
		}
	}
	a {
		@include flex-box;
		@include align-items(center);
		@include justify-content(center);
		width: 100%;
		height: 100%;
		color: $beta;
		font-size: rem-calc(15);
		text-align: center;
		padding: 0 rem-calc(20);
		background: $delta_light;
		transition: color $transition, background $transition;
		&:hover {
			color: $alpha;
		}
		&.js__selected {
			color: $alpha;
			background: white;
			cursor: default;
		}
		&.disabled {
			&:hover {
				color: $beta;
				cursor: default;
			}
		}
	}
}
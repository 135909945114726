@import "../abstracts/all";

.header {
  display: block;
  background-image: linear-gradient(0deg, #4279bd 0%, #458de3 100%);
  box-shadow: 0 50px 50px 0 rgba(11, 34, 50, 0.1),
    0 6px 12px 0 rgba(11, 34, 50, 0.1);
  height: 260px;

  &.header--old {
    display: none;
    background: white;
    background-image: none;
    height: auto;
    box-shadow: none;
    margin-bottom: 24px;

    @media (max-width: 915px) {
      display: block;
    }
  }
}

.alert-bar {
  overflow: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6baae3;
  background: -moz-linear-gradient(top, #6baae3 0%, #78b7e9 100%);
  background: -webkit-linear-gradient(top, #6baae3 0%, #78b7e9 100%);
  background: linear-gradient(to bottom, #6baae3 0%, #78b7e9 100%);
  padding-top: $gutter--12 * 1.5;
  padding-bottom: $gutter--12 * 1.5;

  &__wrap {
    @extend %container;

    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 rem-calc(20);

    p {
      color: white;
      font-weight: 600;

      strong {
        color: white;
        font-weight: 900;
      }
    }
  }

  &__icon {
    background: #5294d6;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message {
    flex: 1;
    overflow: hidden;
    background: #5294d6;
    border-radius: 3px;
    margin-left: $gutter--12;
    width: 90%;
    position: relative;

    > div {
      padding: $gutter--12/2 $gutter--12 * 2;
      align-items: center;
      justify-content: center;
      overflow: auto;
      max-height: 55px;
    }

    &--open {
      > div {
        max-height: none;
      }
    }

    &--open,
    &--scrolled {
      &:after,
      &:before {
        opacity: 0;
      }
    }

    &--overflow:after,
    &--overflow:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      transition-duration: 0.2;
    }

    &:after {
      bottom: 0;
      height: 18px;
      border-radius: 0 0 3px 3px;
      background: linear-gradient(
        to bottom,
        rgba(255, 148, 214, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }

    &:before {
      top: 0;
      height: 6px;
      background: linear-gradient(
        to top,
        rgba(82, 148, 214, 0) 0%,
        rgba(82, 148, 214, 1) 100%
      );
    }

    p {
      text-align: center;
      font-size: 14px;

      @include respond-to("bp-small", max) {
        font-size: 12px;
      }
    }
  }
}

.donation_portal {
  .rccs {
    display: none;

    @include respond-to('medium-down') {
      display: block;
    }
  }

  .rccs__card--unknown > div {
    background: $psi;
    color: $blue--mid;
  }

  .payment_summary {
    padding: $base--8 * 3;
    width: 90%;

    @include respond-to('medium-down') {
      max-width: 600px;
    }

    &__wrap {
      background: $body-background-light;
      padding: $base--8 * 3;
      margin-bottom: $base--8 * 2;

      h4 {
        text-transform: none;
        font-size: 18px;
        width: 160px;
        position: relative;
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          font-weight: 400;
          display: inline-block;
          margin-left: $base--8 * 2;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;

          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      p {
        margin-bottom: $base--8/2;
      }
    }

    &__block {
      margin-bottom: $base--8 * 3;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__error_wrap {
      background: $error;
      padding: $base--8 $base--8 * 2;
    }

    &__error {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }

    &__head {
      font-size: 18px;

      span {
        font-weight: 600;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;

      .save-button {
        height: 40px;
        font-weight: 600;
      }
    }
  }

  .align-center {
    align-items: center;
  }
}

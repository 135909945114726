@import "../abstracts/all";

$font-heading: "Open Sans", sans-serif;
$font-copy: "Open Sans", sans-serif;
$icon: "Material Icons";

// New

p {
  &.small {
    font-size: 12px;
  }
}

.fs--14 {
  font-size: 14px;
}

.fs--12 {
  font-size: 12px;
}

h1 {
  // color:white;
  font-size: 32px;
  margin-bottom: $base--8 * 4;
}

h2,
.h2 {
  &--dark {
    @extend %h2--dark;
  }
}

.white {
  color: white;
}

.dl-horizontal {
  margin-top: 0;
  margin-bottom: 5px;
}

.dl-horizontal dt {
  float: left;
  width: 160px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 85%;
  line-height: 1.2rem;
}

.dl-horizontal dt.full {
  margin-top: 12px;
  float: none;
  width: 100%;
  text-align: center;
}

.dl-horizontal dd {
  font-size: 85%;
  margin-left: 175px;
  line-height: 1.2rem;
}

.dl-horizontal dd:after {
  display: table;
  content: " ";
  clear: both;
}

//	-------------------------------------------------------------
//
//	MISCELLANEOUS VIEW
//
//	-------------------------------------------------------------

// 404 page
body {
	&.not_found {
		.donation_portal__main {
			.afes_logo {
				display: block;
				width: rem-calc(200);
				margin-bottom: rem-calc(25);
				svg {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			h2 {
				margin-bottom: 0;
			}
			.btn_pri {
				width: rem-calc(200);
			}
		}
	}
}
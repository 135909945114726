/**
 * Input syling
 * @by Christian Dixon-McIver
 */

@import "../../abstracts/all.scss";

input,
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
.datebutton-custom-input,
textarea {
  @extend %form__field;
  @extend %input__border;

  width: 100%;
  background: #fff;

  &::placeholder {
    transition: all 2s ease;
    color: $body-color--lightest;
  }

  &.has-icon {
    padding-right: $base--8 * 4;
  }

  &.error {
    border-color: $error;
    padding-right: $base--8 * 4;

    ~ .icon {
      transition: all 0.3s $bezier;
      transition-delay: 0.1s;
      opacity: 0;
      visibility: hidden;
    }

    ~ .input__alert-icon {
      opacity: 1;
      visibility: visible;

      &:hover {
        opacity: 0;
        visibility: hidden;
      }

      &:hover ~ .input__error {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    &:focus ~ .input__alert-icon,
    &:hover ~ .input__alert-icon {
      opacity: 0;
      visibility: hidden;
    }

    &:focus ~ .input__error,
    &:hover ~ .input__error {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.phone-number .input__error {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button.datebutton-custom-input {
  padding-right: 30px;
  min-height: 40px;
  min-width: 115px;
}

input[type="checkbox"] {
  &.error {
    outline: 1px solid $error;
  }
}

input[type="password"] {
  letter-spacing: 4px;

  @include placeholder {
    letter-spacing: 0;
    transition: all 2s ease;
    color: $body-color--lightest;
  }
}

.form__confirm_password {
  height: 84px;
}

input[type="checkbox"] {
  width: auto;
}

// Sets padding for inputs based on position of icon
@mixin input-with-icon($direction) {
  @if ($direction == "right") {
    $direction: "right";
  }

  @if ($direction == "left") {
    $direction: "left";
  }

  position: relative;

  input,
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  textarea {
    padding-#{$direction}: $base--8 * 4;
  }
}

.input {
  &__icon {
    position: relative;

    &--right {
      @include input-with-icon("right");
    }

    &--left {
      @include input-with-icon("left");
    }
  }

  &__alert-icon {
    transition: all 0.3s $bezier;
    transition-delay: 0.2s;
    color: $error;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $input-icon-position;
    width: 16px;
    height: auto;
    opacity: 0;
    visibility: hidden;
  }

  &__focus {
    // transition: all 0.2s ease;
    // position: absolute;
    // top: 40px;
    // height: 1px;
    // width: 100%;
    // background: $blue--mid;
    // transform: scaleX(0) translateY(-1px);
    // border-radius: 100px;
    // transform-origin: left;
    // left: 0;

    input:focus ~ &,
    input:active ~ & {
      transform: scaleY(1) translateY(-1px);
    }

    input.error:focus ~ &,
    input.error:focus ~ & {
      background: $error;
    }
  }
}

input {
  &[type="text"],
  &[type="tel"],
  &[type="number"] {
    height: 40px;
  }
}

input[disabled] {
  opacity: 0.5;
}

input[disabled] ~ .input__focus {
  height: 0;
}

.form__radioGroup {
  padding: 0;
  width: 100%;

  @include respond-to("medium-down") {
    display: flex;
  }

  .form__radio {
    margin-right: $gutter--12;
    height: 40px;
    position: relative;
    width: 100%;
    margin-bottom: $gutter--12;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @include respond-to("medium-down") {
      display: flex;
      margin-bottom: 0;
    }
  }

  label,
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 40px;

    &[type="radio"] {
      opacity: 0.011;
      cursor: pointer;
      width: 100%;

      &:checked + label {
        background: $blue--mid;
        border: 1px solid $blue--mid;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  label {
    padding: 5px;
    border: 1px solid $border--mid;
    cursor: pointer;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    transition: ease 0.4s all;
    font-weight: 500;
    width: 100%;
    font-size: 13px;

    &:hover {
      background: $psi;
    }
  }
}

.react-autosuggest__suggestions-list {
  position: absolute;
  width: 100%;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #bebec6;
  margin-top: 0;
  padding: 0;
  list-style-type: none;
  z-index: 999;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;

  @include respond-to("medium-down") {
    width: calc((100%) - 24px);
  }

  li {
    padding: 8px 24px;
    transition: ease 0.2s all;

    &:hover {
      background: #e4eefb;
      transition: ease 0.2s all;
    }
  }
}

.react-autosuggest__suggestion {
  padding-top: 6px;
  padding-bottom: 6px;
  transition: ease 0.2s all;

  &:hover {
    color: $blue--mid;
    transition: ease 0.2s all;
  }
}

.donation_panel__autocomplete {
  position: relative;

  &:after {
    font-family: material icons;
    content: "search";
    position: absolute;
    top: 50%;
    right: $gutter--12;
    transform: translateY(-50%);
    font-size: 24px;

    @include respond-to("medium-down") {
      right: $base--8 * 4;
    }
  }
}

.donation_panel {
  input {
    height: 40px;
  }
}

.geosuggest__suggests {
  width: 100%;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #bebec6;
  margin-top: 0;
  padding: 0;
  list-style-type: none;

  &--hidden {
    box-shadow: 0;
    border: 0;
    display: none;
  }

  li {
    padding: $base--8 $base--8 * 3;
    transition: ease 0.2s all;

    &:hover {
      background: $psi;
      transition: ease 0.2s all;
      color: $blue--mid;
    }
  }
}

.react-autosuggest__input {
  padding-right: $base--8 * 5 !important;
}

.react-autosuggest {
  display: none;

  &__input {
    padding: $base--8 $base--8 * 5 $base--8 $gutter--12;
  }
}

.staff-report-filters {
  &__search {
    position: relative;

    input {
      height: 40px;
      margin-bottom: 32px;

      &.active {
        padding-left: 40px;
      }
    }
  }

  &__submit {
    position: absolute;
    right: 8px;
    top: 11px;
    color: #5f5c5b;
  }

  &__reset {
    position: absolute;
    left: 8px;
    top: 11px;
    color: #4a90e2;
  }
}

#root .input-start-icon {
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  width: 46px;
  justify-content: center;

  & + input {
    padding-left: 52px !important;
  }
}

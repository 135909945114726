@import '../../abstracts/colours';
@import '../../abstracts/variables';
@import '../../abstracts/breakpoints';

$modal-background: #144261;
$modal-padding: $base--8 * 2;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($modal-background, 0.9);
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background, 300ms, $bezier;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  flex-direction: column;

  &.modal--closed {
    opacity: 0;
    visibility: hidden;
    transition: background, 300ms, $bezier, opacity, 300ms, $bezier;
    background: rgba($modal-background, 0.9);
  }

  &.modal--open {
    transition: background, 300ms, $bezier, opacity, 300ms, $bezier;
    opacity: 1;
    visibility: visible;
  }

  &__wrap {
    background: white;
    box-shadow: 0 25px 50px 0 rgba(34, 135, 201, 0.07), 0 4px 8px 0 rgba(25, 66, 92, 0.03), 0 6px 12px 0 rgba(22, 86, 128, 0.02);
    border-radius: 3px;
    position: relative;
    padding: $modal-padding;
    padding-bottom: 0;
    max-height: 90%;
    width: calc(100% - 70px); // reserve space for close button
    max-width: 780px;
    overflow: auto;

    h2 {
      margin-bottom: $gutter--12 * 2;
      text-align: center;
    }

    .form__wrapper {
      padding: $modal-padding;
    }

    .remodal {
      width: 100%;
      max-width: none;
    }
  }

  &__close {
    z-index: 999;
    position: relative;
    width: calc(100% - 70px);
    max-width: 780px;

    button {
      position: absolute;
      color: #fff;
      left: 100%;
    }
  }

  &__submit-buttons {
    border-top: 1px solid $border--light;
    // display:flex;
    // justify-content: flex-end;
    // align-items:center;
    // position:absolute;
    // bottom:0;
    right: 0;
    padding-top: $base--8 * 2;
    width: 100%;
    background: white;

    &.bleed {
      margin-left: -$modal-padding;
      margin-right: -$modal-padding;
      padding-right: $modal-padding;
      padding-left: $modal-padding;
      box-sizing: content-box;
    }

    a {
      margin: $base--8 0;
      font-size: 14px;

      &:not(:last-of-type) {
        margin-right: $base--8 * 4;
      }
    }

    @include respond-to('small-down', 'max') {
      // padding: 16px;
      background: white;
      z-index: z('default');
    }
  }

  &--login-modal {
    .modal__close {
      max-width: 350px;
    }
  }

  .login-modal {
    max-width: 350px;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 3px;
      background-image: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);
    }

    .save-button {
      width: 100%;
      font-weight: 600;
      height: 40px;

      @include respond-to('medium-down') {
        margin-left: 0;
      }
    }
  }
}

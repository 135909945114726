@import "../../abstracts/all";

.loading .contact-details-modal {
  background: transparent;
}

.contact-details-modal {
  padding: 0;

  .btn__gradient {
    margin-top: 0;
  }

  .contact-details-modal__wrapper {
    padding: 8px;

    @media screen and (min-width: 768px) {
      padding: $base--8 * 4 $base--8 * 6;
    }
  }

  &.loading {
    background: transparent;
  }

  &__content {
    background: #e4eefb;
    color: #565656;
    padding: $base--8 * 2 $base--8;

    @media screen and (min-width: 768px) {
      padding: $base--8 * 3 $base--8 * 4;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #565656;
  }

  h4 {
    padding-top: $base--8;
    margin-bottom: 0;
  }

  div hr {
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 0;
    margin: 8px 0;

    @media screen and (min-width: 768px) {
      margin: 24px 0 32px;
    }
  }

  &__row {
    padding: $base--8/2 0;
    font-size: 16px;
    line-height: 24px;

    .contact-details-modal__label.label-with-meta {
      margin-bottom: 0;
    }
  }

  &__label,
  label,
  &__description {
    font-size: 12px;
  }

  input {
    width: 100%;
  }

  .modal__submit-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .form__row > *:not(:last-of-type),
  .manual-address {
    margin-bottom: 16px;
  }

  .manual-address > div {
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;

    label {
      font-size: 0.7em;
    }
  }

  @media screen and (max-width: 767px) {
    .hide-on-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .form__phone-number .label-with-meta,
    input[type="tel"],
    input.form__email {
      max-width: 250px;
    }

    .manual-address {
      .form__row {
        display: flex;
      }

      .form__row > *:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: 16px;
      }

      .form__row > * {
        flex-basis: 100%;
      }
    }
  }
}

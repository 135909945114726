.modal--terms {
  .modal__wrap {
    padding: 0;
    max-width: 40rem;
    overflow-y: hidden;
  }

  .terms {
    overflow-x: hidden;
  }

  .remodal {
    box-shadow: none;
    overflow-y: auto;

    > div {
      padding: 1.25rem;
    }
  }
}

.remodal--terms_and_conditions {
  overflow-y: auto;
}

legend {
  display: block;
  font-size: 18px;
  color: $blue--mid;
  font-weight: 400;
  margin-bottom: $base--8 * 4;

  &.form__sub-section {
    font-size: 14px;
  }
}

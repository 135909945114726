// -------------------------------------------------------------
//
//	Button styles
//
//	------------------------------------------------------------

@import "../abstracts/all";

.login_button_header {
  &:hover:after {
    transform: none !important;
  }
}

a {
  color: $blue--mid;
  display: inline-block;
  position: relative;
  font-size: inherit;

  &:after {
    content: "";
    transition: transform 0.2s ease;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $blue--mid;
    transform: scale(0, 1) translateY(2px);
    transform-origin: right center;
  }
}

@mixin btn__solid($color, $background, $radius: 100px) {
  height: 40px;
  min-width: 120px;
  transition: all 700ms $bezier;
  // display:block;
  color: $color;
  background: $background;
  border: 1px solid $background;
  border-radius: $radius;
  font-size: 14px;
  padding: $base--8 $base--8 * 3;
  box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0),
    0 30px 50px 0 rgba(11, 34, 50, 0);

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1),
      0 30px 50px 0 rgba(11, 34, 50, 0.1);
    // transform:translateY(-2px);
  }

  &:disabled,
  &.disabled {
    background: $disabled-background;
    border: 1px solid $disabled-background;
    color: $disabled-color;
    cursor: default;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}

.btn {
  &__solid {
    &--positive {
      @include btn__solid(white, $blue--mid);
    }

    &--white {
      @include btn__solid($blue--mid, white);
    }
  }

  &__ghost {
    @include btn__solid($blue--mid, white);

    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px 0 rgba(11, 34, 50, 0.05),
      0 10px 40px 0 rgba(11, 34, 50, 0.05) !important;

    &:hover {
      box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1),
        0 30px 50px 0 rgba(11, 34, 50, 0.1) !important;
    }
  }

  &__link {
    &--neutral {
      color: $body-color--light;

      &:after {
        background: $body-color--light;
      }

      &:hover {
        color: darken($body-color--light, 7.5%);
      }
    }

    &--text {
      color: #4a90e2;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      padding: 0;
    }
  }

  &__gradient {
    height: 40px;
    min-width: 100%;
    transition: all 700ms $bezier;
    display: block;
    border-radius: 40px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    padding: $base--8 $base--8 * 3;
    box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0),
      0 30px 50px 0 rgba(11, 34, 50, 0);
    background: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);

    &:disabled {
      background: linear-gradient(-167deg, #b2b2b2 0%, #8f8f8f 100%);
      opacity: 0.6;
    }

    @include respond-to("medium-down") {
      min-width: 130px;
      margin-top: 0;
    }
  }
}

.save-button {
  z-index: 0;
  transition: all 700ms $bezier;
  position: relative;
  box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0),
    0 30px 50px 0 rgba(11, 34, 50, 0);
  border-radius: 100px;
  margin-bottom: $gutter--12;

  @include respond-to("medium-down") {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1),
      0 30px 50px 0 rgba(11, 34, 50, 0.1);
    // transform:translateY(-2px);
  }

  &:not(:last-child) {
    margin-right: 0;
  }

  &__validation {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &__check,
  &__error,
  &__error-text {
    transition: $default-transition;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 6px));
  }

  &__error-text {
    transform: translate(-50%, -12px);
    margin: 0;
    top: initial;
    bottom: 100%;
    width: 100%;

    &:after {
      $tooltip-size: 6px;

      top: initial;
      bottom: -$tooltip-size;

      @include triangle("down", $tooltip-size, $error);
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding: $base--8/2 $base--8;
    display: flex;
    justify-content: center;
    align-items: center;

    @include btn__solid(white, transparent);

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &__try-again {
    color: $error;
    margin: $gutter--12 0;
    margin-left: auto;
    font-size: 12px;
    padding: $base--8/2 $base--8;
  }

  &--flat {
    .save-button__validation {
      @include btn__solid($body-font-color, transparent);

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    .save-button__text {
      @include btn__solid($body-font-color, transparent);

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }
  }

  &.reset-button {
    .spinner {
      display: none;
    }
  }

  .spinner {
    &:before {
      display: none;
    }
  }

  &.is-saving {
    .save-button__text {
      transition: $default-transition;
      opacity: 0;
    }

    .spinner {
      &:before {
        display: block;

        @include spinner(16px, white);
      }
    }

    &.is-success .spinner:before,
    &.is-error .spinner:before {
      opacity: 0;
    }
  }

  &.is-success {
    .save-button__check {
      visibility: visible;
      opacity: 1;
      color: white;
      transform: translate(-50%, -50%);
    }
  }

  &.is-error {
    .save-button__validation {
      background: $error;
      border-color: $error;
    }

    .save-button__error {
      visibility: visible;
      opacity: 1;
      color: white;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    ~ .save-button__error-text {
      visibility: visible;
      opacity: 1;
    }
  }
}

.login_button_header {
  font-weight: 600;
  transition: ease 0.2s all;

  &:hover {
    background: #fff !important;
    color: $blue--mid !important;
    transition: ease 0.2s all;
  }

  &:after {
    height: 0;
  }
}

.inline_buttons {
  @include respond-to("large-up") {
    display: flex;
    justify-content: flex-end;
  }

  .btn_pri {
    margin-right: 0;
  }
}

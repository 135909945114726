.file_maker_down {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: $base--8 * 4;
    max-width: 800px;

    h1 {
      font-size: 120px;
    }

    h6 {
      font-size: 36px;
      margin-bottom: $base--8 * 2;
    }


    svg {
      align-self: flex-end;
      width: 100px;
    }
  }
}

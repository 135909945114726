label {
  display: block;
  font-size: 12px;
  color: $body-font-color;
  margin-bottom: $base--8 * 0.75;
  font-weight: 400;

  &__error {
    color: $error;
  }
}

.form_note {
  padding: 0;
}

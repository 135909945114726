//	-------------------------------------------------------------
//
//	DETAILS FORM — ADDRESS
//
//	-------------------------------------------------------------

// title
.address_details__title {
	position: relative;
	width: 100%;
	margin-bottom: rem-calc(5);		
	h3 {
		margin-bottom: 0;
	}
}

// form layout
.address_details__search {
	display: none;
	[data-address-active=search] & {
		display: block;
	}
}	

.address_details__manual {
	display: none;
	[data-address-active=manual] & {
		display: block;
	}
}

// close manual address details button
.close_manual_adress_details {
	display: none;
	position: absolute;
	top: 50%;
	right: 0;
	@include transform(translateY(-50%));
	[data-address-active=manual] & {
		display: block;
	}
}


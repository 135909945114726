@import "../../abstracts/all";

.loading .support-changes-modal {
  background: transparent;
}

.support-changes-modal {
  padding: 0;

  .support-changes-modal__wrapper {
    padding: 8px;

    @media screen and (min-width: 768px) {
      padding: $base--8 * 4 $base--8 * 6;
    }

    .table__heading:first-child,
    .table__item:first-child {
      flex-grow: 0.5;
    }

    .table__row .table__item:first-child {
      color: #3a7bd5;
    }
  }

  &.loading {
    background: transparent;
  }

  h4 {
    padding-top: $base--8;
    margin-bottom: 0;
  }
}

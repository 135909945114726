select {
  height: 40px;
  border: 1px solid $border--mid;
  // border-radius: 20px;
  padding: 0 $base--8 * 3;
}

.Select {
  height: 40px;

  .Select-input,
  .Select-control {
    height: 40px;
  }

  .Select-value-label {
    height: 40px;
    display: block !important;
    line-height: 24px !important;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.donation_panel {
  .Select-value {
    padding-right: 0;

    @include respond-to('medium-down') {
      padding-right: 42px;
    }
  }

  .Select-value-label {
    justify-content: center;
  }

  .Select-placeholder {
    text-align: center;
    margin-top: 2px;
    color: $body-font-color;
    padding: 0;

    @include respond-to('medium-down') {
      padding-right: 20px;
    }
  }

  .Select-control {
    border-radius: 100px !important;
  }

  .Select-value-label,
  .Select-placeholder {
    font-weight: 500;
    font-size: 14px;
  }

  .is-focused {
    .Select-control {
      background: $blue--mid !important;
      border: 1px solid $blue--mid !important;
    }

    .Select-placeholder {
      color: #fff !important;
    }
  }

  .has-value {
    .Select-value {
      padding-right: 0 !important;
      padding-left: 0 !important;

      @include respond-to('medium-down') {
        padding-right: 42px !important;
        padding-left: $base--8 * 3 !important;
      }
    }

    .Select-control {
      background: $blue--mid;
      border: 1px solid $blue--mid;
    }

    .Select-value-label {
      color: #fff !important;
    }

    .Select-arrow {
      border-color: #fff transparent transparent;
    }
  }
}

.staff-report-filters__type {
  margin-bottom: 16px;
}

//	-------------------------------------------------------------
//
//	TYPOGRAPHY
//
//	------------------------------------------------------------

// declaring transition variable again, unsure why this file wont read _config.scss
$transition: 175ms ease-out;

// font variables
$base: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$icon: 'Material Icons';

$font_regular: 400;
$font_bold: 700;

// base font
body {
	color: $body_font_color;
	font-family: $base;
	font-weight: $font_regular;
  	font-kerning: normal;
  	line-height: 1;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

// headings
h1, h2, h3,
h4, h5, h6 {
	margin: 0;
	font-weight: normal;
}

h1, .h1 {
	font-size: rem-calc(32);
	color: $alpha;
	line-height: 1.2;
	margin: 0 0 rem-calc(20) 0;
	// breakpoints / media queries
	@include bp(small) {
		font-size: rem-calc(28);
	}
}

h2, .h2 {
	font-size: rem-calc(22);
	color: $alpha;
	line-height: 1.2;
	margin: 0 0 rem-calc(18) 0;
	// breakpoints / media queries
	@include bp(small) {
		font-size: rem-calc(18);
	}
}

h3, .h3 {
	color: $alpha;
	font-size: rem-calc(15);
	margin: 0 0 rem-calc(20) 0;
}

h4, .h4 {
	color: $alpha;
	font-size: rem-calc(12);
	text-transform: uppercase;
	font-weight: $font_bold;
	margin: 0 0 rem-calc(10) 0;
}

h5, .h5 {
	// ..
}

h6, .h6 {
	// ..
}

// paragraphs
p {
	font-size: rem-calc(15);
	color: $gamma;
	line-height: 1.5;
	margin: 0 0 rem-calc(20) 0;
	&:only-child,
	&:last-child {
		margin: 0;
	}
	strong {
		color: lighten($gamma,15%);
	}
	&.p_hero {
		font-size: rem-calc(18);
	}
	&.p_note {
		color: $omega;
		font-size: rem-calc(13);
		line-height: 1.2;
		margin-top: rem-calc(10);
		padding: 0 rem-calc(10);
	}

	.contact_page_promo & {
		color: #fff;
		line-height:0;
	}
}

// formatting
strong {
	font-weight: $font_bold;
}

// alignment
.text_center {
	text-align: center;
}

.text_right {
	text-align: right;
}

// links
a {
	color: $alpha;
	text-decoration: none;
}

.text_link {
	color: black;
	position: relative;
	transition: color $transition;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -(rem-calc(2));
		width: 100%;
		height: rem-calc(1);
		background: $gamma;
		transition: transform $transition, background $transition;
		@include transform(scale(0,1));
		@include transform-origin(right center);
	}
	&:hover,
	&.js__selected {
		color: $alpha;
		&:after {
			background: $alpha;
			@include transform(scale(1,1));
			@include transform-origin(left center);
		}
	}
	&.c_alpha {
		color: $alpha;
	}
}

.text_link_basic {
	color: black;
	position: relative;
	border-bottom: 1px solid;
	&:hover {
		color: $alpha;
	}
	&.c_alpha {
		color: $alpha;
	}
}

// lists
.ol {
	font-size: rem-calc(15);
	line-height: 1.5;
	padding-left: rem-calc(20);
	li {
		margin-bottom: rem-calc(20);
	}
}

.panel__content {
	ol,
	ul {
		list-style-type: none;
		font-size: rem-calc(15);
		color: $gamma;
		line-height: 1.5;
		padding: 0;
		margin: 0 0 rem-calc(20) 0;
	}
}

// other
.numbered_heading {
	@include flex-box;
	@include align-items(center);
	span {
		width: rem-calc(22);
		height: rem-calc(22);
		color: $alpha;
		font-size: rem-calc(15);
		line-height: rem-calc(20);
		border: 1px solid $alpha;
		border-radius: 100%;
		text-align: center;
		margin-right: rem-calc(10);
	}
}

.contact_page_promo {
	height: 70px;
	overflow: hidden;
}

.form__sugery-ooze {
	display: none !important;
	visibility: hidden;
}

textarea.form_input {
	height: 7.5rem;
}

.contact_content {
	padding-top: 15px;
}

.right_aligned {
	text-align: right;
}

.contact_page_editable_content {
	p {
		&:last-of-type {
			margin-bottom: 25px;
		}
	}
}
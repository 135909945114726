/**
 * MIXINS
 *
 * Simplified SASS mixins designed to be cross-compatible with Compass
 *
 * @package		Zon\Front End
 *
 * @author		Glen Honeybone <glen@customd.com>
 * @link		http://www.customd.com
 * @version		1.1.0
 * @license		MIT - http://opensource.customd.com/license
 *
 */

//	----------------------------------------------------------------------------------
//	Media Queries
//	@param 	$point		The target min-width in pixels, em, rem (you can use rem-calc too)
//	@returns			A media-query with min-width of the point you defined.

@mixin breakpoint($point) {
	@media (min-width: rem-calc($point)) { @content; }
}

/**
 * 	!!! IMPORTANT !!!
 * 	The following mixins are included for backwards compatability with older projects.
 * 	More recent projects use postcss/autoprefixer as part of the compiling process so you won't need to use these.
 * 	Check it PostCSS & AutoPrefixer are included in your gruntfile before using the mixins below. If so, no more mixins, no more prefixing!
 */

//	----------------------------------------------------------------------------------
//	BOX SIZING
@mixin box-sizing($box-sizing) {
	-webkit-box-sizing: $box-sizing;
	   -moz-box-sizing: $box-sizing;
		 -o-box-sizing: $box-sizing;
		 	box-sizing: $box-sizing;
}

//	----------------------------------------------------------------------------------
//	BORDER RADIUS
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	   -moz-border-radius: $radius;
		 -o-border-radius: $radius;
		 	border-radius: $radius;
}

//	----------------------------------------------------------------------------------
//	BOX SHADOW
@mixin box-shadow($box-shadow) {
	-webkit-box-shadow: $box-shadow;
	   -moz-box-shadow: $box-shadow;
		 -o-box-shadow: $box-shadow;
		 	box-shadow: $box-shadow;
}

//	----------------------------------------------------------------------------------
//TEXT SHADOW
@mixin text-shadow($shadow) {
	-webkit-text-shadow: $shadow;
	   -moz-text-shadow: $shadow;
		 -o-text-shadow: $shadow;
		 	text-shadow: $shadow;
}

//	----------------------------------------------------------------------------------
// GRADIENTS
@mixin gradient-vertical ($firstColor: #FFF, $secondColor: #DDD) {
	background	: $firstColor; /* Fallback solid color for old browsers */
	background	: linear-gradient(to bottom, $firstColor 0%, $secondColor 100%); /* W3C */
	background	: -moz-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* FF 3.6+ */
	background	: -ms-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* IE 10+ */
	background	: -o-linear-gradient(top, $firstColor 0%, $secondColor 100%); /* Opera 11.10+ */
	background	: -webkit-linear-gradient(top, $firstColor 0%,$secondColor 100%); /* Chrome 10+, Safari 5.1+ */
	background	: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$firstColor), color-stop(100%,$secondColor)); /* Chrome, Safari 4+ */
	filter		: progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$firstColor}', endColorstr= '#{$secondColor}', GradientType=0); /* IE 6-9 */
}

@mixin gradient-horizontal ($firstColor: #FFF, $secondColor: #DDD) {
	background	: $firstColor; /* Fallback solid color for old browsers */
	background	: linear-gradient(to right, $firstColor 0%, $secondColor 100%); /* W3C */
	background	: -moz-linear-gradient(left,  $firstColor 0%, $secondColor 100%); /* FF 3.6+ */
	background	: -ms-linear-gradient(left, $firstColor 0%, $secondColor 100%); /* IE 10+ */
	background	: -o-linear-gradient(left, $firstColor 0%, $secondColor 100%); /* Opera 11.10+ */
	background	: -webkit-linear-gradient(left,  $firstColor 0%,$secondColor 100%); /* Chrome 10+, Safari 5.1+ */
	background	: -webkit-gradient(linear, left top, right top, color-stop(0%,$firstColor), color-stop(100%,$secondColor)); /* Chrome, Safari 4+ */
	filter		: progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$firstColor}', endColorstr= '#{$secondColor}', GradientType=1); /* IE 6-9 */
}

//	----------------------------------------------------------------------------------
// COLUMNS
@mixin columns($width, $count, $gutters:gutters()) {
	-webkit-columns: $width $count;
	   -moz-columns: $width $count;
			columns: $width $count;
	-webkit-column-gap: $gutters;
	   -moz-column-gap: $gutters;
			column-gap: $gutters;
}

/**
 *	ANIMATION
 */

// @include this mixin to provide vendor-prefixed animations.
// Default values are set for:
// 		$duration (1 second) – the time to complete one occurrence of the animation
// 		$occurrences - the number of times the animation repeats (a positive integer, or 'infinite')
//		$easing - "linear" (no easing), "ease", "ease-in", "ease-out"
@mixin animation($name, $duration: 1s, $occurences: infinite, $easing: linear ) {
	-webkit-animation: $name $duration $occurences $easing;
	   -moz-animation: $name $duration $occurences $easing;
		 -o-animation: $name $duration $occurences $easing;
			animation: $name $duration $occurences $easing;
}

/**
 *	TRANSFORMS
 */

//	GENERIC TRANSFORM
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	   -moz-transform: $transforms;
		 -o-transform: $transforms;
         	transform: $transforms;
}

//	ROTATE
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

//	SCALE
@mixin scale($scale) {
	 @include transform(scale($scale));
}

//	TRANSLATE
@mixin translate ($x-offset, $y-offset) {
   @include transform(translate($x-offset, $y-offset));
}

//	SKEW
@mixin skew ($x-offset, $y-offset) {
   @include transform(skew(#{$x-offset}deg-offset, #{$y}deg));
}

@mixin transform-origin ($origin) {
	-webkit-transform-origin: $origin;
       -moz-transform-origin: $origin;
		 -o-transform-origin: $origin;
         	transform-origin: $origin;
}

/**
 *	TRANSITIONS
 */

@mixin transition-property($property) {
	-webkit-transition-property: $property;
	   -moz-transition-property: $property;
		 -o-transition-property: $property;
	 		transition-property: $property;
}

@mixin transition-duration($duration) {
	-webkit-transition-duration: $duration;
	   -moz-transition-duration: $duration;
		 -o-transition-duration: $duration;
		 	transition-duration: $duration;
}

@mixin transition-delay($duration) {
	-webkit-transition-duration: $duration;
	   -moz-transition-duration: $duration;
		 -o-transition-duration: $duration;
		 	transition-duration: $duration;
}


@mixin transition($properties...) {
		-webkit-transition: $properties;
		   -moz-transition: $properties;
			 -o-transition: $properties;
			 	transition: $properties;
}
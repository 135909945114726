.cc-logos {
  float: right;
  gap: 16px;
  margin-left: 16px;

  img {
    width: 40px;
    height: auto;
  }
}

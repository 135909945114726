@import "../abstracts/all";

.programme-details {
  &__field {
    display: flex;
    font-size: 14px;
    line-height: 17px;
    padding: $base--8 0;

    .label {
      flex-basis: 25%;
    }
  }

  .label {
    font-weight: 500;
    color: #3a7bd5;
  }

  .value {
    margin-left: 8px;
  }

  .dollars {
    text-align: right;
  }

  &__wrapper {
    border: 1px solid #cbcbcb;
    border-radius: 2px;
    display: flex;
    padding: $base--8;
    margin: $base--8 * 2 0;
    justify-content: flex-end;

    &.programme-details__totals,
    &--singular .programme-details__wrapper {
      border-bottom: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 2px 2px 0 0;
    }

    &.programme-details__totals ~ .programme-details__totals,
    &--singular .programme-details__totals {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
      border-bottom: 1px solid #cbcbcb;
      border-radius: 0 0 2px 2px;
      position: relative;

      &:before {
        content: "";
        display: block;
        top: -1px;
        left: 8px;
        right: 8px;
        height: 1px;
        background: #cbcbcb;
        position: absolute;
      }
    }

    &-desktop {
      @media screen and (min-width: 768px) {
        border: 1px solid #cbcbcb;
        border-radius: 2px;
        display: block;
        padding: $base--8 $base--8 * 2;
        margin: $base--8 * 2 0;

        .programme-details {
          &__wrapper {
            border: 0 none;
            margin: 0;
            padding: 0;
            flex-basis: 50%;

            .programme-details__field {
              flex-basis: 33%;
            }
          }

          &__staffworker {
            display: flex;

            &:not(:first-child) .label {
              display: none;
            }
          }

          &__field {
            flex-direction: column;
            flex-basis: 25%;
          }

          &__totals {
            border-top: 1px solid #cbcbcb;
            margin-top: 8px;
            padding-top: 8px;

            & ~ .programme-details__totals {
              border-top: none;
              margin-top: 0;
              padding-top: 0;
              border-bottom: 0;

              &:before {
                display: none;
              }
            }

            .programme-details__field {
              flex-basis: 16%;

              &:first-child {
                flex-basis: 60%;
                padding-left: 0;
              }
            }
          }
        }

        .label {
          padding-bottom: $base--8;
          white-space: nowrap;
        }
      }
    }

    .label {
      padding-bottom: $base--8;
    }

    .programme-details__field {
      padding: $base--8;
      display: flex;
      flex-direction: column;
      min-width: 90px;

      &:first-child {
        margin-right: auto;
        width: auto;
      }
    }

    &.programme-details__targets .programme-details__field {
      width: 100%;
    }
  }

  .programme-details__totals {
    display: flex;
    justify-content: flex-end;

    > * {
      padding: $base--8;
    }
  }

  .party-popper {
    height: 20px;
    width: auto;
    line-height: 28px;
    vertical-align: middle;
  }

  @media screen and (min-width: 768px) {
    .value {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h2 {
      flex-basis: 100%;
      margin-bottom: 0;
    }

    .programme-details__wrapper-desktop {
      flex-basis: 65%;
    }

    > .programme-details__wrapper {
      flex-basis: 30%;
      flex-grow: 1;
      margin-left: 16px;

      .programme-details__field {
        justify-content: space-around;
      }
    }
  }
}

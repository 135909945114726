@import "../abstracts/all";

.nav {
  @extend %container;

  padding-top: $base--8 * 6;
  position: relative;
  z-index: 100;

  &__actions {
    bottom: $base--8/2;
  }

  &__desktop {
    font-weight: 600;
  }

  &__wrap {
    position: relative;

    @include clearfix();

    padding-bottom: $base--8;
    height: 25px;
    z-index: 99;

    @include respond-to("large-up") {
      height: 35px;
    }

    > hr {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: white;
      margin: 0;
      border: 0;
    }
  }

  &__alert {
    $size: 16px;

    height: $size;
    width: $size;
    position: relative;
    bottom: 2px;

    /* left:-$size - $base--8*1.5; */
    display: inline-block;

    svg {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: linear-gradient(
        -180deg,
        rgba(235, 40, 95, 0.31) 0%,
        #eb285f 100%
      );
      box-shadow: 0 3px 6px 0 rgba(11, 34, 50, 0.14),
        0 6px 12px 0 rgba(11, 34, 50, 0.1);
      border-radius: 3px;
      height: $size;
      width: $size;
      z-index: 99;
      padding: 3px;
      cursor: pointer;
    }
  }

  &__logo {
    position: absolute;
    left: 45%;
    bottom: $base--8;

    @include respond-to("large-up") {
      left: 0;
    }
  }

  &__security {
    color: white;
  }

  &__actions {
    position: relative;
    z-index: z("default");
    display: flex;
    align-items: center;
  }

  &__desktop {
    display: none;

    @include respond-to("large-up") {
      display: block;
    }

    .active {
      font-weight: bold;
    }

    a:after {
      background: white;
    }
  }

  &__username {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    font-size: 12px;
    color: white;
    display: block;
    min-width: 120px;
    text-align: right;

    @include respond-to("large-up") {
      display: block;
      top: -20px;
    }
  }

  .fancy-nav {
    color: #fff;
    margin: 0 $base--8;
    font-size: 14px;

    &.login_button_header {
      background: #fff;
      padding: $base--8 $base--8 * 3;
      border-radius: 50px;
      color: #1e88e5;

      &:hover {
        text-decoration: none;
        font-weight: bold;
      }

      &.secondary {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
      }
    }

    @include respond-to("large-up") {
      margin: 0 $base--8 * 2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tabbed_nav {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 $base--8 * 5;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.125rem;
    background-image: linear-gradient(-167deg, #00d2ff 0%, #3a7bd5 100%);
  }

  &__inner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  &__item {
    justify-self: center;
  }

  &__link {
    display: flex;
    align-items: center;
    transition: ease 0.2s all;
    font-weight: 500;

    .tabbed_nav__number {
      color: $beta;
      border-color: $beta;
      transition: ease 0.2s all;
    }

    .tabbed_nav__title {
      color: $beta;
      transition: ease 0.2s all;
      font-size: 17px;
    }

    &.js__selected {
      .tabbed_nav__number {
        color: $blue--mid;
        border-color: $blue--mid;
        transition: ease 0.2s all;
      }

      .tabbed_nav__title {
        color: $blue--mid;
        font-weight: 500;
        transition: ease 0.2s all;
      }
    }
  }

  &__number {
    width: 22px;
    height: 22px;
    color: $body-color--dark;
    font-size: 15px;
    line-height: 20px;
    border: 1px solid $blue--mid;
    border-radius: 100%;
    transition: color $transition, border-color $transition;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $gutter--12;
  }
}

@import "../abstracts/all";

.history {
  &__wrap {
    padding: $base--8 * 4;
  }

  &__summary {
    font-size: 12px;
  }
}

.support-history {
  margin-top: $base--8 * 6;
}

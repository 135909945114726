/* ---------------------------------

	Default Carousel Styles

--------------------------------- */

.carousel_slide__link {
	display: block;
	position: relative;
}

.carousel_slide__copy {
	@include text-shadow(rgba(0,0,0,0.5) 1px 1px 1px);
	color: #fff;
	padding: 2em;
	position: absolute;
}

.carousel_slide__title {
	margin: 0 0 0.5em 0;
}

.carousel_slide__caption {
	margin: 0;
	line-height: 1.5em;
}

// Template Variations

.carousel_slide--top-left {
	.carousel_slide__copy {
		top: 0;
		left: 0;
	}
}

.carousel_slide--top-right {
	.carousel_slide__copy {
		top: 0;
		right: 0;
	}
}

.carousel_slide--bottom-left {
	.carousel_slide__copy {
		bottom: 0;
		left: 0;
	}
}

.carousel_slide--bottom-right {
	.carousel_slide__copy {
		bottom: 0;
		right: 0;
	}
}


.carousel_slide--panel-left {
	.carousel_slide__copy {
		background: rgba($omega, 0.8);
		top:0;
		bottom: 0;
		left: 0;
		width: 32%
	}
}

.carousel_slide--panel-right {
	.carousel_slide__copy {
		background: rgba($omega, 0.8);
		top:0;
		bottom: 0;
		right: 0;
		width: 32%
	}
}
//	-------------------------------------------------------------
//
//	HEADER LAYOUT
//
//	-------------------------------------------------------------

.header {
  &__tool_bar_inner,
  &__main {
    @extend .donation_portal__container;
  }
  &__tool_bar {
    width: 100%;
    background: white;
    padding: rem-calc(14) 0 rem-calc(16) 0;
    border-bottom: 1px solid $kappa;
    @include clearfix;
    .text_link {
      color: $alpha;
      font-size: rem-calc(12);
      font-weight: $font_bold;
      margin-left: rem-calc(22);
      &:first-child,
      &:only-child {
        margin: 0;
      }
    }
  }
  &__tool_bar_left {
    float: left;
  }
  &__tool_bar_right {
    float: right;
  }
  &__main {
    @include flex-box;
    @include align-items(center);
    padding-top: rem-calc(28);
    padding-bottom: rem-calc(28);
  }
  &__welcome {
    @include flex-grow(2);
    @include order(1);
    h2 {
      margin-bottom: rem-calc(2);
    }
  }
  &__logo {
    @include flex-grow(1);
    @include order(2);
    text-align: right;
    a {
      display: inline-block;
      width: rem-calc(120);
      height: auto;
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  // breakpoints / media queries
  @include bp(medium) {
    display: block;
  }
  @include bp(small) {
    &__main {
      display: block;
    }
    &__welcome {
      width: 100%;
      text-align: center;
    }
    &__logo {
      width: 100%;
      text-align: center;
      margin-bottom: rem-calc(20);
    }
  }
}

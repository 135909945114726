@import "../abstracts/all";
@import "../abstracts/typography";

$dropdown-padding: $gutter--12 * 3;

.fancy-dropdown {
  position: relative;
  cursor: pointer;
  float: left;
  z-index: inherit;

  @include respond-to("large-up") {
    display: none;
  }

  // @include respond-to('large-up') {
  // 	display: none;
  // }

  h5 {
    color: white;
    font-size: 14px;
    margin-right: $base--8 * 2;
    padding-right: $base--8 * 3;
    position: relative;
    cursor: pointer;
    user-select: none;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: white;
      font-size: 1.5rem;
    }

    &:after {
      background: white;
    }
  }

  &__username {
    // display: none;

    @include respond-to("large-up") {
      display: none;
    }
  }

  &__top {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include respond-to("large-up") {
      display: none;
    }
  }

  &__menu {
    transition: transform 450ms $bezier, opacity 450ms ease;
    position: absolute;
    left: 0;
    top: -6px;
    background: white;
    box-shadow: 0 6px 12px 0 rgba(11, 34, 50, 0.1),
      0 30px 50px 0 rgba(11, 34, 50, 0.1);
    z-index: z(dropdown);
    transform: translateY(-32px) scaleY(0);
    transform-origin: 0% 5%;
    opacity: 0;
    border-radius: 3px;
    cursor: default;

    > * {
      transition: transform 300ms $bezier, opacity 300ms ease;
      transform: translateY(-16px);
      opacity: 0;
      transition-delay: 150ms;
    }

    .is-open & {
      transition: transform 450ms $bezier, opacity 450ms ease;
      transform: translateY(0) scaleY(1);
      opacity: 1;
      transform-origin: 0% 5%;

      > * {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .nav & {
      transform: translateY(-12px) scaleY(0);
    }

    .nav .is-open & {
      transform: translateY(40px) scaleY(1);
    }
  }

  &__list {
    list-style-type: none;
    padding: $gutter--12 * 1.5 $base--8 * 3.5;
    margin: 0;

    @include respond-to("large-up") {
      padding: $gutter--12 $base--8 * 3.5;
    }
  }

  &__item {
    margin: $base--8 * 2 0;

    @include respond-to("large-up") {
      margin-bottom: 16px !important;
    }

    &--divider {
      @include respond-to("large-up") {
        display: none;
      }
    }

    > hr {
      border: 0;
      margin: $base--8 0;
      height: 1px;
      width: 100%;
      background: $body-color--lightest;

      @include respond-to("large-up") {
        display: none;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    position: relative;
    transition: all 0.3s ease;
    white-space: nowrap;
    color: $blue--mid;
    font-size: 14px;
    margin-right: $base--8 * 6;
    opacity: 0;
    transform: translateY(-12px) translateZ(0);

    .is-open & {
      transition-delay: 100ms;
      opacity: 1;
      transform: translateY(0) translateZ(0);
    }

    &:after {
      background-color: $blue--mid;
    }

    &.secondary {
      opacity: 1;
      color: $body_font_color;

      &:after {
        background-color: $body_font_color;
      }
    }
  }
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  padding-right: $dropdown-padding;

  .Select--icon & {
    padding-left: $dropdown-padding;
  }

  p {
    line-height: 1;
  }
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  @extend %form__field;
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  @extend %form__field;

  padding-left: 0;
  padding-right: 0;
  line-height: 16px; // 2px more to compensate for no border;
  border: none;
}

.Select {
  cursor: pointer;

  .Select-control {
    @extend %form__field;
    @extend %input__border;

    padding: 0;
    display: block;
    height: auto;
    cursor: pointer;

    .is-open {
      border-color: $blue--mid;
    }
  }

  &.is-disabled > .Select-control {
    padding: 8px 12px;
    opacity: 0.5;
  }

  .Select-option {
    @extend %form__field;

    position: relative;

    .Select--icon & {
      padding-left: $dropdown-padding;
    }

    &.is-selected,
    &.is-selected * {
      color: $blue--mid;
    }
  }

  .Select-input {
    height: auto;

    > input {
      @extend %form__field;
    }
  }

  .Select-arrow-zone {
    position: absolute;
    right: $input-icon-position;
    top: 50%;
    transform: translateY(-50%);
  }

  .Select-menu-outer {
    overflow: hidden;
    z-index: 10;

    .Select-option {
      background: transparent;

      &:hover {
        background: mix(#fff, $blue--mid, 90%);
      }
    }
  }

  &__arrow {
    right: $input-icon-position;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $input-icon-position;
  }

  .Select-value-label > *,
  .Select-value-label {
    color: $body-font-color;
  }
}

.form__select.internal-payment-method .Select-placeholder {
  color: #000;
}

//	-------------------------------------------------------------
//
//	BASE
//
//	-------------------------------------------------------------

body {
	background: $alpha;
	&.donation_receipt {
		background: white;
	}
}

// helper classes
.hidden,
.hide {
	display: none !important;
}

.text-right {
	text-align:right;
}
.text-left {
	text-align:left;
}
.text-center {
	text-align:center;
}

.cm {
	margin: 0;
}

// colors
.c_alpha {
	color: $alpha;
}

// hide & show helpers based on certain breakpoints
.show_at_bp_small {
	display: none;
	@include bp(small) {
		display: block;
	}
}

.hide_at_bp_small {
	@include bp(small) {
		display: none !important;
	}
}

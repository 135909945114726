$icon: 'Material Icons';

%input__border {
  border: 1px solid $border--mid;
  border-radius: 3px;
  transition: 0.3s all ease;

  &:focus,
  &:active {
    outline: none;
    border-color: $blue--mid;

    &::placeholder {
      opacity: 0.2;
    }
  }
}

.form_section {
  border-bottom: 0;
}

.form_error {
  font-size: 12px;
  color: $error;
  margin-top: $base--8;
  font-weight: 500;
}

.form_input_wrap {
  width: 100%;
  position: relative;
  z-index: 1;

  .form_input {
    position: relative;
    z-index: 2;
  }
}

.form_input_wrap {
  input {
    padding-left: $base--8 * 6 !important;
  }
}

.form_input_icon {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 39px;
  height: 38px;
  background: $psi;
  z-index: 3;

  input {
    padding-left: $base--8 * 5;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $alpha;
    font-family: $icon;
    font-size: rem-calc(18);
    transform: translate(-50%, -50%);
  }

  &--dollar {
    &:after {
      content: 'attach_money';
    }
  }

  &--close {
    position: absolute;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background: $psi;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &--help-circle {
    &:after {
      content: 'help-circle';
    }
  }
}

.radio_label {
  &.active {
    background: $blue--mid;
    border: 1px solid $blue--mid;
    color: #fff;

    &:hover {
      background: $blue--mid;
      border: 1px solid $blue--mid;
      color: #fff;
    }
  }
}

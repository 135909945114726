//	-------------------------------------------------------------
//
//	VALIDATION
//
//	------------------------------------------------------------

.validation {
	width: 100%;
	.alert-box {
		position: relative;
		font-size: rem-calc(14);
		padding: rem-calc(8) rem-calc(10);
		margin-bottom: rem-calc(10);
		border-radius: rem-calc(3);
		color: white;
		font-weight: $font_bold;
		a {
			color: white;
			text-decoration: underline;
		}
		p {
			font-size: rem-calc(14);
			line-height: 1;
			color: white;
			margin: 0;
		}
		.close {
			display: none;
		}
		ul {
			@include listreset;
			position: relative;
			
		}
		li {
			display: block;
			// margin: rem-calc(5) 0;
		}
		&:last-child,
		&:only-child {
			margin-bottom: rem-calc(20);
		}
		&.alert {
			background: #FF4C4C; // set as color var
			&.has_alert_icon {
				padding-left: rem-calc(40);
				&:before {
					position: absolute;
					top: 50%;
					left: rem-calc(10);
					content: "error_outline";
					font-family: $icon;
					font-size: rem-calc(22);
					@include transform(translateY(-50%));
				}		
			}
		}
		&.success {
			background: #3FD25E; // set as color var
		}
		&.info {
			background: #10BAF4; // set as color var
		}
	}
}

[data-content-group="main-form"] {
	.validation {
		.alert-box {
			margin-bottom: rem-calc(30);
		}
	}
}

// here for reference
// <div class="alert alert-box validation_errors" style="display: block;">
// 	<ul>
// 		<li>Please select where you would like your donations to go</li>
// 	</ul>
// </div>

// here for reference
// <div class="validation" for="auth_form">
// 	<div data-alert="" class="alert-box alert ajax-submit-alert" style="display: none;">
// 		<a class="validate-link" for="password" href="#password" form="auth_form">Password</a> is required.<a href="" class="close">×</a>
// 	</div>
// 	<div data-alert="" class="alert-box alert ajax-submit-alert">
// 		<a class="validate-link" for="password" href="#password" form="auth_form">Password</a> is required.<a href="" class="close">×</a>
// 	</div>
// </div>
//	-------------------------------------------------------------
//
//	FOOTER
//
//	-------------------------------------------------------------

.footer {
  width: 100%;
  font-weight: $font_bold;
  background: $alpha;
  &__inner {
    @include container(1140px);
    padding: rem-calc(15) rem-calc(20) rem-calc(18);
  }
  // &__left,
  // &__right {
  // 	width: 50%;
  // }
  &__left {
    float: left;
  }
  &__right {
    float: right;
    text-align: right;
  }
  span,
  .text_link {
    color: rgba(255, 255, 255, 0.8);
    margin-left: rem-calc(22);
    font-size: rem-calc(12);
    &:only-child {
      margin: 0;
    }
  }
  .text_link {
    &:after {
      background: white;
    }
    &:hover {
      color: white;
      &:after {
        background: white;
      }
    }
  }
  // breakpoints / media queries
  @include bp(small) {
    &__left,
    &__right {
      float: left;
      width: 100%;
      text-align: center;
    }
    &__left {
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(10);
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    }
  }
}

.success {
  padding-top: $gutter--12 * 3;
  padding-bottom: $gutter--12 * 4;

  @include respond-to('medium-down') {
    padding-top: $gutter--12 * 7;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: $base--8 * 4;

    @include respond-to('medium-down') {
      font-size: 26px;
      line-height: 36px;
    }

    span {
      font-weight: 600;
    }
  }

  p {
    font-size: 16px;

    span {
      font-weight: 600;
    }
  }

  &__row {
    display: flex;
    font-size: 16px;
  }

  &__label {
    color: $blue--mid;
    font-weight: 500;
    margin-right: $base--8/2;
    margin-bottom: $gutter--12;
  }

  &__block {
    margin-top: $base--8 * 4;
  }

  &__foot {
    line-height: 1.5;
  }

  &__buttons {
    border-top: 1px solid $border--light;
    padding-top: $base--8 * 4;
    margin-top: $base--8 * 4;
  }
}

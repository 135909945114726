@import "mixins";
@import "variables";
@import "colours";

.mb--24 {
  margin-bottom: $gutter--24;
}

$spacer-values: 8, 12, 16, 24, 32, 40;

@each $spacer-values in $spacer-values {
  .mt--#{$spacer-values} {
    margin-top: #{$spacer-values}px;
  }

  .mr--#{$spacer-values} {
    margin-right: #{$spacer-values}px;
  }

  .mb--#{$spacer-values} {
    margin-bottom: #{$spacer-values}px;
  }

  .ml--#{$spacer-values} {
    margin-left: #{$spacer-values}px;
  }

  .pt--#{$spacer-values} {
    padding-top: #{$spacer-values}px;
  }

  .pr--#{$spacer-values} {
    padding-right: #{$spacer-values}px;
  }

  .pb--#{$spacer-values} {
    padding-bottom: #{$spacer-values}px;
  }

  .pl--#{$spacer-values} {
    padding-left: #{$spacer-values}px;
  }
}

.relative {
  position: relative;
}

.stretch {
  width: 100%;
}

.overflow--hidden {
  overflow: hidden;
}

.inline-block {
  display: inline-block;
}

.blue {
  color: $blue--mid !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

.position {
  &__center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--right {
      @extend .position__center;

      right: 0;

      input ~ &,
      .input__icon--right & {
        right: $input-icon-position;
      }
    }

    &--left {
      @extend .position__center;

      right: 0;

      input ~ & {
        left: $input-icon-position;
      }
    }
  }
}

.cf {
  @include clearfix();
}

.is-not-interactive {
  pointer-events: none;
}

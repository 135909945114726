$rem-base: 16px;

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

// Creates a triangle
@mixin triangle($direction, $size: 8px, $color: #222) {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;

  @if ($direction == 'up') {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: none;
  }

  @else if ($direction == 'down') {
    border-top: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: none;
  }

  @else if ($direction == 'left') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-left: none;
  }

  @else if ($direction == 'right') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-right: none;
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }

  & {
    *zoom: 1;
  }
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  @if ($value == 0) {
    $value: 0;
  } // Turn 0rem into 0

  @return $value;
}

// Foundation's rem-calc unit conversion mixin
@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();

  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }

  @return $remValues;
}

// Creates spinner
@mixin spinner($size, $color: $blue--mid) {
  @keyframes spinner {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $size;
  height: $size;
  border-radius: 50%;
  border-top: 2px solid $blue--mid;
  border-top-color: $color;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  transform: translate(-50%, -50%) rotate(0);
}

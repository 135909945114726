//	-------------------------------------------------------------
//
//	PDF RECEIPTS
//
//	-------------------------------------------------------------


.donation_receipt {
	position: relative;
	background: white;
	font-family: Metric, Helvetica, Arial, Verdana, Sans-Serif;
	width: 210mm;
	font-size: rem-calc(14);
	line-height: 1.2;
}

.donation_receipt_pdf {
	position: relative;
	width: 100%;
	padding: rem-calc(40);

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 15px;
		background-image: linear-gradient(-167deg, #00D2FF 0%, #3A7BD5 100%);
	}

	// grid
	.row {
		max-width: rem-calc(1024);
		.columns {
			padding: 0;
		}
	}

	// typography
	h1, h2, h3 {
		line-height: 1.2;
		margin: 0;
	}

	h1 {
		font-size: rem-calc(26);
	}

	h2 {
		font-size: rem-calc(20);
	}

	h3 {
		font-size: rem-calc(18);
	}

	p {
		font-size: rem-calc(16);
		line-height: 1.2;
		margin: 0 0 rem-calc(10) 0;
	}

	ol {
		padding-bottom: rem-calc(10);
		margin: 0;
		li {
			line-height: 1.2;
			margin-bottom: rem-calc(8);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// layout
	&__upper {
		width: 100%;
	}

	&__lower {
		// position set with JS
		position: absolute;
		width: 100%;
		// bottom: 0;
	}

	// various areas
	&__head {
		width: 100%;
		padding: rem-calc(10) 0;
		border-bottom: 1px solid $alpha;
		h2 {
			padding-bottom: rem-calc(15);
		}
		.company_numbers {
			strong {
				font-weight: normal;
				color: $alpha;
			}
			span {
				margin-right: rem-calc(10);
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	&__invoice_details {
		width: 100%;
		padding-top: rem-calc(20);
		margin-bottom: rem-calc(20);
		.date {

		}
		h3 {
			padding-top: rem-calc(15);
			span {
				color: $body_font_color;
			}
		}
	}

	.blue {
		color: $alpha;
	}


	.grey {
		color: $body-font-color;
	}

	&__invoice_number {
		font-size: 18px !important;
		margin-top: rem-calc(8) !important;
	}

	&__invoice_heading {
		width: 65%;
		float:left;
		margin-top: rem-calc(4) !important;
		h1 {
			font-size: rem-calc(20);
			font-weight: bold;
		}
	}

	&__invoice_date {
		font-size: 15px !important;
		margin-top: rem-calc(8) !important;
		width: 30%;
		float:right;
	}

	&__address {
		width: 100%;
		font-size: rem-calc(18);
		padding-top: rem-calc(5);
		font-weight: 600;
		color: rgba(0,0,0,0.50);
	}

	&__body {
		width: 100%;
		padding: rem-calc(20) 0;
	}

	&__summary {
		width: 100%;
		// display: none;
		padding-bottom: rem-calc(20);
		h1 {
			padding-bottom: rem-calc(15);
		}
		h2 {
			font-size: rem-calc(16);
		}
		.summary_table {

			&__row {
				width: 100%;
				padding: rem-calc(10) 0;
				border-bottom: 1px solid $alpha;
				@include clearfix;

				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					border: none;
				}
			}
			&__col {
				float: left;
				font-size: rem-calc(16);
				&--large {
					width: percentage(3/12);
				}
				// &--medium {
				// 	width: percentage(3/12);
				// }
				&--small {
					width: percentage(2.25/12);
				}

			}
		}
	}

	&__table {
		width: 100%;
	}

	&__cell {
		text-align: left;
		font-weight: 400;
		font-size: 16px;
		flex: 1;
		display: inline-block;

		&--date {
			width: 15%;
		}

		&--support {
			width: 40%;
		}

		&--card {
			width: 30%;
		}

		&--frequency {
			width: 15%;
		}

		&--amount {
			width: 13%;
			text-align: right;
			white-space: nowrap;
			//float: right;
		}

		&--total {
			width: 61%;
			color: $alpha;
		}

	}

	&__empty {
		// width: 80%;
	}

	&__total {
		margin-top: rem-calc(12);
		width: 25%;
		float: right;
		padding: rem-calc(12) rem-calc(8);
		border-top: 1px solid $alpha;
		border-bottom: 1px solid $alpha;

		&--amount {
			white-space: nowrap;
			text-align: right;
			float: right;
		}
	}

	&__row {
		display: flex;
		padding: rem-calc(12) rem-calc(8);
    	border-top: 1px solid $alpha;

		&:first-child {
			color: $alpha;
			border-top: 0;
		}

		&:last-child {
			padding: rem-calc(12) 0;
		}
	}

	&__legals {
		width: 100%;
		padding-bottom: rem-calc(10);
		p {
			font-size: rem-calc(14);
		}
		span {
			display: inline-block;
			color: $alpha;
			padding-bottom: rem-calc(5);
		}
		ol {
			padding-left: 20px;
		}
		.direct_debit_terms {
			ol, ol li, p, h4 {
				font-size: rem-calc(11);
				font-weight: normal;
				margin-bottom: 0;
			}
			ol {
				padding-left: 15px;
			}
		}
	}

	&__foot {
		width: 100%;
		border-top: 1px solid $alpha;
		text-align: center;
		font-size: rem-calc(16);
		padding: rem-calc(15) 0 0 0;
		strong {
			color: $alpha;
			margin-left: rem-calc(10);
			font-weight: normal;
		}
		span {
			color: $alpha;
		}
	}


	.annual_table {
		width: 100%;
		border-top: 1px solid $alpha;
		padding-top: rem-calc(32);

		&__row {
			display: flex;
			justify-content: space-between;
			padding: rem-calc(4) 0;

			&:first-child {
				margin-bottom: rem-calc(8);

				.annual_table__cell {
					font-size: 18px;
				}
			}
		}

		&__group {
			margin-bottom: rem-calc(8);
			margin-bottom: rem-calc(12);
			background: #F6F6F6;
			padding: rem-calc(8);
			@include clearfix;
		}

		&__headings {

			.annual_table__cell {

				&--date {
					margin-left: 10px;
				}

				&--receipt {
					margin-left: -10px;
				}
			}
		}

		&__total {
			@include clearfix;
			padding: rem-calc(8);
		}

		&__cell {
			font-weight: 400;
			font-size: 14px;
			padding: 0;
			display: inline-block;

			&--date {
				width: 20%;
			}

			&--type {
				width: 35%;
			}

			&--receipt {
				width: 25%;
			}

			&--amount {
				text-align: right;
				width: 9%;
				float:right;

				span {
					padding-bottom: rem-calc(2);
					border-bottom: 2px solid #333;
				}
			}

			&--grandtotal {
				text-align: right;
				float: right;
				font-size: 18px;
				padding: rem-calc(8) 0;
				border-top: 2px solid #333;
				border-bottom: 2px solid #333;
				margin: 0 !important;

				span {
					margin-right: rem-calc(32);
				}
			}

			&--name {
				font-weight: 600;
				color: #333;
				width: 55%;
				font-size: 16px !important;
			}

			&--text {
				width: 25%;
				text-align: right;
				font-size: 14px !important;
			}

			&--count {
				text-align: right;
				margin-left: -10px;
				margin-right: 10px;
				width: 15px;
			}

			&--total {
				font-size: 18px;
				text-align: right;
				margin-top: rem-calc(8);
				border-top: 1px solid #888;
				padding: rem-calc(2) 0;
				width: 15%;
				float: right;

			}
		}
	}

}

.form_row {
	&.has_payment_icons {
		position: relative;
	}
}

.payment_icons {
	position: absolute;
	top: 50%;
	right: 0;
	@include transform(translateY(-50%));
	@include clearfix;
	&__item {
		float: left;
		width: rem-calc(46);
		margin-bottom: rem-calc(5);
		margin-right: rem-calc(5);
		&:last-child {
			margin-right: 0;
		}	
		svg {
			display: block;
			width: 100%;
		}
	}
}
@import '../password-strength';

.modal--change-password {
  .change-password {
    width: 100%;
    max-width: 400px;
    padding: 0;
    .form__finalise-changes {
        margin-top: 20px;
      }
  }

  .modal__submit-buttons {
    display: flex;
    justify-content: flex-end;

    .save-button {
      margin-left: 0;
    }
  }
}

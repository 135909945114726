//	-------------------------------------------------------------
//
//	DONTATION FORM
//
//	-------------------------------------------------------------

.search_info_icon {
	$button_size: rem-calc(24);
	position: absolute;
	top: 50%;
	right: -($button_size+rem-calc(8));
	width: $button_size;
	height: $button_size;
	color: $alpha;
	margin-top: - $button_size / 2;
	cursor: pointer;
	&:after {
		font-family: $icon;
		content: "info_outline";
		@include stretch;
		font-size: $button_size;
	}
}

// search notify box positioned to cover the "or"
.search_notify_box {
	position: absolute;
	left: 0;
	top: 50%;
	opacity: 0;
	margin: 0;
	transition: opacity $transition;
	z-index: 99;
	padding-top: rem-calc(8);
	padding-bottom: rem-calc(8);
	@include transform(translateY(-50%));
	&.is_active {
		opacity: 1;
	}
}

.general_fund_label {
	position: relative;
	max-width: rem-calc(186); // roughly equal to 1/3 form column
	margin: 0 auto;
	&:hover {
		+ .general_fund_notify_box {
			opacity: 1;
		}
	}
	// breakpoints / media queries
	@include bp(small) {
		max-width: 100%;
	}
}

.general_fund_notify_box {
	position: absolute;
	left: 0;
	right: 0;
	top: calc(100% + #{rem-calc(10)});
	opacity: 0;
	transition: opacity $transition;
	margin: 0 auto;
	max-width: rem-calc(385); // roughly equal to the size of .form_column--2-3
	z-index: 999;
}

.scrollbox {
    max-height: 250px;
    overflow-x: auto;
    padding: 5px;
    border: 1px solid #cecece;
    margin-bottom: 15px;
    font-size: 0.75rem;
    h3 {
	font-weight: bold;
    }
    ol
    {
	li {
	    margin-bottom:8px;
	    line-height: 0.82rem;
	}
    }
    p{
	font-size: 0.75rem;
    }
}
/**
 *	 _____ _            _____      _     _
 *  |_   _| |          |  __ \    (_)   | |
 *    | | | |__   ___  | |  \/_ __ _  __| |
 *    | | | '_ \ / _ \ | | __| '__| |/ _` |
 *    | | | | | |  __/ | |_\ \ |  | | (_| |
 *    \_/ |_| |_|\___|  \____/_|  |_|\__,_|
 *
 * Based on Foundation 5's grid mixins and classes, this version is
 * set up to use Susy to calculate its values.
 *
 * There's a whole blog article about it here: https://www.customd.com/articles/41/from-foundation-to-susy
 * An a github repo here: https://github.com/glenatcd/fusy-scss (fork it!)
 *
 * @package		Zon\Front End
 *
 * @author		Glen Honeybone <glen@customd.com>
 * @link		http://www.customd.com
 * @version		1.1.0
 * @license		MIT - http://opensource.customd.com/license
 *
 */


/*
 *	GRID MIXIN
 *	We run this to generate the grid classes to add to our markup
 *
 */

// Set up our rows using Susy's container
.row {
	@include container;
	width: 100%;
	&.collapse {
	 	margin-left: 0;
	 	margin-right: 0;
		 > .columns {
		 	margin-left: 0;
		 	margin-right: 0;
			@if map-get($susy, gutter-position) == 'inside-static' {
				padding-left: 0;
		 		padding-right: 0;
			}
		 }

	}
	.row {
		width: auto;
		margin-left: (gutter()) * -1;
		margin-right: (gutter()) * -1;
		padding: 0;
		&.collapse {
	 		margin-left: 0;
	 		margin-right: 0;
	 	}
	}
}

// Set up columns, defaulted to full-width and with gutters
.columns {
	padding-left: gutter();
	padding-right: gutter();
	width: 100%;
	float: left;
	position: relative;

	// Float the last child right so it's lined up (helps pixel rounding)
	&:last-child:not(:first-child) {
		@include last();
		&.end {
			float: left;
		}
	}
}

@mixin grid-classes($total-columns , $gutters , $size) {

	/*
	 *	Main column span classes
	 */

	// Loop through our specified number of columns and calculate their spans

	@for $i from 1 through $total-columns {
		.#{$size}-#{$i} {
			@include span($i);
		}
	}


	/*
	 *	Offset classes
	 */

	// Loop through our specified number of columns and calculate their offsets.
	// We don't need the last one, hence the -1

	@for $i from 0 through $total-columns - 1 {
		.#{$size}-offset-#{$i} {
			@include push($i);
		}
	}

	/*
	 *	Push and pull classes
	 */

	// Loop through our specified number of columns and calculate their push and pull margins.
	@for $i from 1 through $total-columns - 1 {
		.#{$size}-push-#{$i} {
			position: relative;
			left: span($i of 12 $gutters fluid);
		}
		.#{$size}-pull-#{$i} {
			position: relative;
			left: span($i of 12 $gutters fluid) * -1;
		}
	}

	// Reset push and pull
	.#{$size}-push-0 {
		margin-left: 0;
	}
	.#{$size}-pull-0 {
		margin-right: 0;
	}

	/*
	 *	Centered classes
	 */

	// Reset float an apply margins
	.#{$size}-centered {
		margin-left: auto;
		margin-right: auto;
		float: none;
	}


	.column.#{$size}-uncentered,
	.columns.#{$size}-uncentered {
		float: left;
		margin-left: 0;
	}

	// Fighting [class*="column"] + [class*="column"]:last-child
	.column.#{$size}-centered:last-child,
	.columns.#{$size}-centered:last-child{
		float: none;
	}

	// Fighting .column.<previous-size>-centered:last-child
	.column.#{$size}-uncentered:last-child,
	.columns.#{$size}-uncentered:last-child {
		float: left;
	}

}


// Churn out our grid classes for specified breakpoints,
// if we've turn them on in _config.scss

@if ($include-small == true) {
	@media #{$small-up} {
		@include grid-classes($total-columns, gutter() * 0.5 , $size:small);
	}
}

@if ($include-medium == true) {
	@media #{$medium-up} {
		@include grid-classes($total-columns, gutter() * 0.5 , $size:medium);
	}
}

@if ($include-large == true) {
	@media #{$large-up} {
		@include grid-classes($total-columns, gutter() * 0.5 , $size:large);
	}
}

@if ($include-xlarge == true) {
	@media #{$xlarge-up} {
		@include grid-classes($total-columns, gutter() * 0.5 , $size:xlarge);
	}
}

@if ($include-xxlarge == true) {
	@media #{$xxlarge-up} {
		@include grid-classes($total-columns, gutter() * 0.5 , $size:xxlarge);
	}
}



//	-------------------------------------------------------------
//
//	MODALS
//
//	------------------------------------------------------------

// NOTE: modals follow the same form structure as normal views

// global
.remodal {
	position: relative;
	box-shadow: 0 0 24px 0 rgba(0,0,0,0.12);
	overflow: hidden;
	@include clearfix;
	// graident border top
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: rem-calc(2);
		background-image: linear-gradient(-167deg, #00D2FF 0%, #3A7BD5 100%);
	}
	&--help {
		overflow: auto;
	}
	.form_section {
		padding: rem-calc(20);
		border-bottom: none;
	}
	.remodal_large {
		padding: rem-calc(40);
	}
	.form_foot {
		padding: 0;
	}
	.form_note {
		padding: 0;
	}
	&__column_wrap {
		@include flex-box;
	}
	&__column {
		width: 50%;
		&--fill {
			background: lighten($psi,2%);
		}
		.remodal__content,
		.form_section {
			padding: rem-calc(40) rem-calc(20);
		}
	}
	&__content {
		width: 100%;
		padding: rem-calc(20);
		h2 {
			max-width: rem-calc(220);
		}
		p {
			max-width: rem-calc(260);
		}
	}
	&__close {
		$size: rem-calc(24);
		position: absolute;
		top: rem-calc(7);
		right: rem-calc(5);
		width: $size;
		height: $size;
		z-index: 999;
		&:after {
			font-size: rem-calc(18);
			line-height: $size;
		}
	}
	&__validation {
		width: 100%;
		text-align: center;
		.alert-box {
			font-weight: $font_regular;
			border-radius: 0;
			padding-bottom: rem-calc(2);
			margin: 0;
			.close {
				display: none;
			}
			&:first-child {
				padding-top: rem-calc(10);
			}
			&:last-child {
				padding-bottom: rem-calc(10);
				margin-bottom: 0;
			}
			&:only-child {
				margin-bottom: 0;
			}
		}
		.validate-link {
			position: relative;
			color: white;
		}
	}
	// terms and conditions modal content
	&__terms_head,
	&__terms_body {
		width: 100%;
		padding: rem-calc(20);
	}
	&__terms_head {
		padding-top: rem-calc(30);
		background: lighten($psi,2%);
		p {
			max-width: rem-calc(540);
		}
	}
	&__terms_body {
		ol {
			max-width: rem-calc(540);
		}
	}
	// breakpoints / media queries
	@include bp(small) {
		&__column_wrap {
			display: block;
		}
		&__column {
			width: 100%;
			.remodal__content,
			.form_section {
				padding: rem-calc(20);
			}
		}
		&__content {
			width: 100%;
			padding: rem-calc(20);
			h2 {
				max-width: 100%;
			}
			p {
				max-width: 100%;
			}
		}
	}
}

// various modal sizes
.remodal {
	&--login,
	&--forgotpassword {
		max-width: rem-calc(320);
	}
	&--donatedbefore,
	&--help,
	&--terms_and_conditions {
		max-width: rem-calc(640);
	}
}

// custom remodal styles — overwrites the default remodal styles
.remodal-overlay {
	background: rgba(20, 66, 97, 0.9);
}

.remodal {
    margin-bottom: 0;
    padding: 0;
    color: $gamma;
    background: white;
	text-align: left;
}

// various remodal specific styles
.remodal {
	&--forgotpassword {
		.form_note {
			margin-top: 0;
		}
	}
	&--terms_and_conditions {
		.btn_close {
			&:hover {
				background: white;
			}
		}
	}
	&--processing {
		background: transparent;
		box-shadow: none;
		&:before {
			display: none;
		}
	}
}

// Need Help? Modal on donation screen.
.help_content {
	width: 100%;
	margin: 0 auto;
	&--top {
		padding: rem-calc(20);
		padding-top: rem-calc(30);
	}
	&--bottom {
		padding: rem-calc(20);
		padding-bottom: rem-calc(30);
		background: lighten($psi,2%);
	}
	h2 {
		margin-bottom: rem-calc(24);
	}
	// breakpoints / media queries
	@include bp(small) {
		padding: rem-calc(40) rem-calc(20);
	}
}

.help_contact {
	width: 100%;
	&__row {
		width: 100%;
		@include clearfix;
	}
	&__column {
		width: 30%;
		float: left;
		margin-bottom: rem-calc(5);
	}
	strong {
		width: 100%;
		font-size: rem-calc(13);
	}
	a,
	span {
		font-size: rem-calc(15);
		line-height: 1.4;
	}
	.text_link {
		color: $gamma;
		&:hover {
			color: $alpha;
		}
	}
	// breakpoints / media queries
	@include bp(small) {
		&__row {
			margin-bottom: rem-calc(10);
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__column {
			width: 100%;
		}
	}
}

// processing
.remodal {
	&--processing {
		// ..
	}
	.remodal_spinner {
		$spinner_color: $alpha;
		$loader_color: rgba(255,255,255,0.85);
		$loader_size: rem-calc(60);
		$border_size: rem-calc(8);
		height: $loader_size;
		width: $loader_size;
		border-radius: 100%;
		border: $border_size solid $loader_color;
		border-left-color: $spinner_color;
		margin: 0 auto;
		@include animation(animate_remodal_loader 1.1s infinite linear);
	}
}

@include keyframes(animate_remodal_loader) {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}

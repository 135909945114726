@import "../abstracts/colours";
@import "../abstracts/variables";
@import "../abstracts/all.scss";

.password-strength {
  margin: $base--8 * 5 0;

  strong {
    font-size: 12px;
  }

  &__bar {
    width: 100%;
    height: 2px;
    position: relative;
    background: $border--lightest;
    margin: $base--8 0;
    visibility: hidden;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 85%;
      background: $blue--mid;
    }
  }

  p {
    font-size: 12px;
  }
}

form {
  .ReactPasswordStrength {
    border: none;
    height: 34px;

    &-input {
      @extend %form__field;
      @extend %input__border;

      width: 100%;
      background: #fff;

      &::placeholder {
        transition: all 2s ease;
        color: $body-color--lightest;
      }

      &.has-icon {
        padding-right: $base--8 * 4;
      }
    }

    &-strength-bar {
      top: -15px;
        left: 2px;
        width: calc(100% - 4px) !important;
      padding: 0;
      margin: 0;
      display: inline-block;
      height: 1px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    &-strength-desc {
      position: relative;
      top: -16px;
      padding: 0;
      margin: 0;
      display: inline-block;
      width: 100%;
      text-align: left;
      left: 2px;
      font-size: 12px;
      font-weight: 700;
      font-family: inherit;
      font-style: inherit;
      text-transform: capitalize;
    }
  }
}

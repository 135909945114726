fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.fieldset {
  padding: 0;
  margin: 0;
  border: 0;

  &__sub-section {
    margin-bottom: $gutter-spacing;
    padding-bottom: $gutter-spacing;
    border-bottom: 1px solid $border--light;
  }
}

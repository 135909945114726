@import "../abstracts/breakpoints";

.accordion {
  &__content {
    display: none;
    transition: ease 0.2s all;

    .active & {
      display: block;
      transition: ease 0.2s all;
    }
  }

  &__header {
    h5 {
      line-height: 1.5;
    }
  }

  &__question {
    max-height: 100px;
    transition: all 1s ease;
    overflow: hidden;

    @include respond-to("medium-down") {
      max-height: 60px;
    }

    &.active {
      max-height: 500px;
      transition: all 1s ease;
    }
  }

  &__question:first-child {
    .accordion__header {
      display: block;

      h5 {
        margin-bottom: $base--8;
      }

      @include respond-to("medium-down") {
        display: flex;
      }

      a {
        width: 95%;
        margin: auto;
        cursor: pointer;
        margin-bottom: $base--8;
        text-align: center;
        display: flex;
        justify-content: center;

        @include respond-to("medium-down") {
          width: auto;
          margin: 0;
          margin-right: $base--8;
        }
      }

      &:hover {
        color: $body-font-color;
      }
    }
  }
}

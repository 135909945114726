.flex-row {
  display: flex;

  > div {
    &:not(:last-of-type) {
      margin-right: $gutter--24;
    }
  }

  &__auto {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__stretch {
    flex: 1;

    .form__row & {
      position: relative;
    }

    &--3-4 {
      flex: 0 1 75%;
    }
  }

  &--aligncenter {
    align-items: center;
  }
}

%form__field {
  line-height: 1;
  padding: $base--8 $gutter--12;
  font-size: 14px;
  color: $body-font-color;
  display: block;
}

%bold {
  font-weight: bold;
}

%black {
  color: $body-font-color;
}

%h2--dark {
  font-weight: bold;
  color: $body-font-color;
}
